/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

:root {
  --primary-color: #17c1e8;
  --secondary-color: #1275c5;
  --accent-color: #354868;
  --background-color: #fafafa;
}

html {
  scroll-behavior: smooth;
  scrollbar-width: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  scroll-behavior: smooth;
}

.centerElm {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnNotFill {
  background: transparent;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  cursor: pointer;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 700;
  min-width: 100px;
  width: auto;
  height: 42px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 1px 20px;
  transition: 0.3s ease;
  letter-spacing: 0.5px;
}

.btnNotFill:hover{
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: #fff;

}

.btnFill {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  min-width: 100px;
  width: auto;
  height: 42px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 1px 20px;
  transition: 0.3s ease;
  letter-spacing: 0.5px;
}

.btnFill:hover {
  background: none;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}


@media (min-width: 320px) and (max-width: 480px) {
  .btnFill{
    height: 36px;
    border-radius: 10px;
    font-size: 12px;
  }

  .btnNotFill{
    height: 36px;
    border-radius: 10px;
    font-size: 12px;
  }
}