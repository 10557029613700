.loginMain_cont{
  /* border: 1px solid blue; */
  height: 100vh;
  width: 100vw;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;
}

.loginCardMain_cont{
  /* border: 1px solid blue; */
  border-radius: 10px;
  background-color: #fff;
  /* height: 70%; */
  height: auto;
  width: 30%;
  padding: 20px;
  margin: 0px auto;
  margin-top: 100px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.loginBrand_cont{
  /* border: 1px solid blue; */
  height: 30px;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
}

.loginBrand_cont img{
  height: 100%;
  width: 30%;
}

.loginWelcomeHead_cont{
  /* border: 1px solid red; */
  text-align: center;
}

.loginWelcomeHead_cont p{
  color: gray;
  font-size: 12px;
}

.formInput_cont{
  /* border: 1px solid green; */
  margin-top: 10px;
}

.loginBtn_cont{
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.loginBtn{
  width: 100%;
  transition: 0.5s ease-out;
}

.loginSignupPara_cont p{
  font-size: 13px;
}
.loginSignupPara_cont p Link strong{
  text-decoration: none;
    color: black;
}

.loginAlert_cont {
  /* border: 1px solid green; */
  height: auto;
  height: 50px;
  margin-top: 14px;
}

.loginAlert {
  border-radius: 10px !important;
}

.forgotPara_cont{
  font-size: 13px;
  text-align: center;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  color: black;
}