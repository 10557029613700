.newTemplate_cont {
  height: auto;
  /* border: 2px solid yellow; */
  border-radius: 10px;

}

.newTemplateHeader {
  /* border: 1px solid green; */
  margin-bottom: 0px;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
}

.crossSvg:hover {
  color: rgb(14, 113, 195);
  /* color: rgb(23, 193, 232); */
}

.templateMessage_cont {
  /* border: 2px solid red; */
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 22px !important;

}

.closeBtn_cont {
  /* border: 2px solid blue; */
  margin-right: 20px;
}

.mainContent_cont {
  /* border: 2px solid yellowgreen; */
  display: flex;
  background-color: #f8f9fa;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.content_cont {
  /* border: 2px solid blue; */
  width: 70vw;
  height: 76vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.content_cont::-webkit-scrollbar {
  display: none;
}

.tcl_cont {
  /* border: 1px solid red; */
  margin: 15px auto;
  background-color: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: auto;
  width: 95%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.templateMain_cont {
  /* border: 2px solid brown; */
  width: 14em;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.templateName_cont {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 12px;
  text-align: center;
}

.asterisk {
  color: red;
}

.templateNameMob_cont {
  display: none;
}

.templateInput_cont {
  /* border: 1px solid green; */
  font-size: 13px;
  color: rgb(51, 51, 51);
}

.templateError_cont {
  /* border: 1px solid blue; */
  margin-bottom: 5px;
  height: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.categoryMain_cont {
  /* border: 2px solid brown; */
  width: 12em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;

}


/* Title main start */


.headerMain_cont {
  /* border: 1px solid red; */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: 15px auto;
  width: 95%;
  height: auto;

}

.broadcastHeading_cont {
  /* border: 2px solid blue; */
  height: 70px;
}

.ptag {
  margin-top: 10px;
  font-size: 13px;
  line-height: 19px;
  color: rgb(51, 51, 51);
}

.optionalSpan {
  font-size: 12px;
  line-height: 18px;
  color: rgb(158, 158, 158);
}

.selectHeaderType_cont {
  /* border: 1px solid blue; */
  margin-bottom: 20px;
}

.headerFormatSelect {
  width: 32%;
}

.textHeader_cont {
  /* border: 2px solid red; */
  width: 100%;
}

.spanCountCont {
  /* border: 1px solid blue; */
  width: 100%;
  padding: 0px 20px;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
}

.imgHeader_cont {
  border: 1px solid lightgray;
  padding: 10px;
  padding-bottom: 0px;
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.uploadInput_cont {
  /* border: 1px solid greenyellow; */
}

.uploadFileBtn_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid blue; */
}

.fileName_cont {
  /* border: 1px solid red; */
  border-radius: 10px;
  margin-left: 10px;
  height: 3.5rem;
  width: 50%;
  font-size: 13px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
}

.fileName {
  color: green;
}

.nofile {
  color: red;
}

.uploadImgLabel {
  margin-top: 20px;
  display: inline-block;
  --color: rgb(14, 113, 195);
  background: none;
  color: var(--color);
  padding: 1px 8px;
  border-radius: 10px;
  cursor: pointer;
  border-width: 1px;
  border: 1px solid rgb(14, 113, 195);
  color: rgb(14, 113, 195);
  font-size: 14px;
  transition: box-shadow 0.3s ease 0s;
  min-width: 100px;
  width: auto;
  height: 2.8rem;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

input[type="file"] {
  display: none;
}

.videoHeader_cont {
  border: 1px solid lightgray;
  padding: 10px;
  padding-bottom: 0px;
  width: 100%;
  height: auto;
  border-radius: 4px;
}

/* B O D Y */

.bodyMain_cont {
  /* border: 2px solid blue; */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: 15px auto;
  width: 95%;
  height: auto;
}

.addVariable_cont {
  /* border: 1px solid blue; */
  margin-bottom: 20px;
}

.addVariableBtns_cont {
  /* border: 1px solid red; */
  margin-bottom: 20px;
  height: auto;
}

.addVariableCont {
  border: 1px solid lightgray;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  /* height: 250px; */
  display: flex;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.addVariableCont::-webkit-scrollbar {
  display: none;
}

.variableCont {
  /* flex: 1 1 130px; */
  border: 1px solid lightgray;
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 4px 12px;
  width: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.createVariableCont {
  border: 1px solid lightgray;
  border-radius: 4px;
  width: 100%;
  height: auto;
  padding: 10px;
  padding-bottom: 0px;
  /* height: 150px; */
  margin-top: 20px;
}

.createVarCont {
  /* border: 1px solid blue; */
  display: flex;
  padding: 10px;
  padding-left: 0px;
}

.createVarHead {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  min-width: 90px;
  width: auto;
  margin-right: 10px;
}


.createVarBtnCont {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.templateMsgMain_cont {
  /* border: 2px solid green; */
  width: 100%;
  border-radius: 4px;
}

.templateMsgInput {
  border: 1px solid lightgray;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  color: rgb(51, 51, 51);
  font-size: 16px;
  resize: none;
}

.templateMsgInput:hover {
  border: 1px solid black;
}

/* F O O T E R */

.templateFooterMain_cont {
  /* border: 1px solid red; */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: 15px auto;
  width: 95%;
  height: auto;
}

.templateFooter_cont {
  /* border: 2px solid red; */
  width: 100%;
}

/* B U T T O N S */

.buttonsMain_cont {
  /* border: 2px solid red; */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  /* height: 370px; */
  margin: 15px auto;
  width: 95%;
  margin-bottom: 10px;
  height: auto;
}

.buttons_cont {
  /* border: 2px solid yellow; */
  width: 100%;
}

.callToActionMain_cont {
  /* border: 1px solid purple; */
}

.callToAction_cont {
  /* border: 2px solid red; */
  margin-bottom: 20px;
  width: 80%;
}

.callMobileLabel_cont {
  /* border: 1px solid purple; */
}

.callInputs_cont {
  /* border: 1px solid green; */
  display: flex;
  justify-content: space-between;
}

.buttonLabelBox {
  /* border: 1px solid violet; */
}

.dialNumberCont {
  /* border: 1px solid purple; */
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.dialSelect {
  border: none;
  min-width: 80px;
  border-radius: 5px;
  margin-right: 4px;
}

.mobileNumberBox {
  /* border: 1px solid red; */
}

.websiteInputs_cont {
  /* border: 1px solid green; */
  display: flex;
  justify-content: space-between;
}

.websiteUrlBox {
  /* border: 1px solid purple; */
  width: 300px;
}

.quickReplyCont {
  /* border: 2px solid red; */
  width: 100%;
  /* display: flex; */
  justify-content: space-between;
}

.quickReplyInputs_cont {
  display: flex;
  justify-content: space-between;
}

.segmentListMain_cont {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  /* border: 2px solid red; */
  padding: 20px;
  margin: 15px auto;
  width: 95%;
  display: flex;
}

.segmentNameForm {
  width: 40%;
}

.broadcastSeg_cont1 {
  /* border: 1px solid blue; */
  width: 50%;
  margin-right: 30px;
}

.broadcastOr_cont {
  /* border: 1px solid blue; */
  margin-right: 30px;
}

.broadcastSeg_cont2 {
  /* border: 1px solid blue; */
  width: 50%;
}

.selectSegmentForm {
  width: 100%;
}


.submitBtn_cont {
  /* border: 1px solid blue; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 95%;
  margin: 20px auto;
}

.submitErrorCont {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
}

.mainOutput_cont {
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  padding: 0px;
  height: 90%;
  width: 30vw;
}

.secOutputMain_cont {
  /* border: 1px solid blue; */
  height: 55%;
  padding: 5px;
  padding-top: 0px;
  width: 20%;
  position: absolute;
  top: 30%;
  right: 5%;
  overflow: scroll;
  scrollbar-width: none;
}

.svgCont {
  /* border: 1px solid blue; */
  display: flex;
  justify-content: center;

  width: 95%;
  position: absolute;
}

.svgCont img {
  height: 100%;
  width: 100%;

}

.output_cont {
  /* border: 1px solid blue; */
  text-align: left;
  background-color: #fff;
  border-radius: 8px;
  min-height: 60px;
  position: relative;
  top: 15%;
  overflow-wrap: break-word;
  padding: 5px;
  margin-top: 10px;
  width: 80%;

}

.outputTitleCont {
  /* border: 1px solid blue; */
  font-size: 12px;
  font-weight: bold;
  line-height: 19px;
  margin-bottom: 5px;
  color: rgb(51, 51, 51);
  visibility: visible !important;
}

.outputMsgSpan {
  font-size: 11px;
  white-space: pre-wrap;
  color: rgb(51, 51, 51);
  margin-bottom: 5px;
  margin-top: 5px;
}

.footerspan {
  font-size: 9px;
  white-space: pre-wrap;
  color: rgb(51, 51, 51);
}

.currentTime {
  font-size: 9px;
  display: flex;
  justify-content: flex-end;
}

.outputRow {
  border-bottom: 0px;
  border-color: #F7F9F2;
  margin-top: 5px;
  margin-bottom: 5px;
}


.outputMobile_cont,
.outputWebsite_cont,
.outputQuickReply {
  text-align: left;
  background-color: #fff;
  border-radius: 10px;
  min-height: 20px;
  position: relative;
  top: 14%;
  font-size: 10px;
  overflow-wrap: break-word;
  padding: 0px 10px;
  width: 80%;
  margin-top: 3px;
}

.outputMobile_cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.outputWebsite_cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.outputButtonText {
  font-size: 11px;
  text-align: center;
  color: blue;
}

.outputSvg_cont {
  justify-content: center;
  color: blue;
  margin-right: 5px;
}





@media (max-width: 480px) {

  .newTemplate_cont {
    height: 100%;
    width: 100%;
    /* border: 1px solid red; */
    overflow: scroll;
    /* overflow-y: scroll; */
    margin-bottom: 0;
    padding-bottom: 0;

  }

  .header {
    width: 100%;
    /* border: 1px solid green; */
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px 20px;
    padding-right: 15px;
    margin-bottom: 10px;
    ox-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  }

  .closeBtn_cont {
    margin: 0;
  }

  .templateMessage_cont {
    /* border: 4px solid purple; */
    /* margin-left: 10px; */
  }

  .mainContent_cont {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 0;
    padding: 0px;
    /* border: 1px solid blue; */
    overflow: auto;
    overflow-y: scroll;
  }

  .content_cont {
    /* border: 1px solid yellowgreen; */
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    /* justify-content: center; */
  }

  .sampleMain_cont {
    /* border: 1px solid olive; */
    height: auto;
    width: 90%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

  }

  .sample_cont {
    /* border: 1px solid orange; */
    text-align: center;
    width: 100%;
    flex-direction: column;
  }

  .sampleBtn {
    margin-left: 0;
  }

  .tcl_cont {
    /* border: 1px solid olive; */
    width: 90%;
    height: auto;
    padding: 10px;
    display: flex;
    /* margin: auto; */
    /* flex-direction: column; */

  }

  .templateMain_cont {
    /* height: auto; */
    /* padding: 0; */
    width: 98%;

    /* border: 1px solid blue; */
    /* display: none; */
  }

  .templateName_cont {
    display: none;
  }

  .templateNameMob_cont {
    /* border: 1px solid green; */
    display: block;
    line-height: 19px;
    margin-bottom: 12px;
    font-size: 15px;
  }

  .template_name {
    height: 30px;
    width: 100%;
  }

  .categoryMain_cont {
    /* height: auto; */
    width: 90%;
    /* border: 1px solid blue; */
    display: none;
  }

  .languageMain_cont {
    /* height: auto; */
    width: 90%;
    /* border: 1px solid blue; */
    display: none;
  }



  .titleMain_cont {
    /* border: 1px solid olive; */
    width: 90%;
    padding: 10px;
  }

  .broadcastHeading_cont {
    /* border: 1px solid palevioletred; */
    height: auto;
    margin-bottom: 10px;
  }

  .broadcastHeading_cont h5 {
    margin: 0;
  }

  .textTypeMain_cont {
    /* border: 1px solid green; */
    font-size: 13px;
    /* height: 50px; */
    width: 100%;
  }

  .textType_cont {
    /* border: 1px solid blue; */
    width: 100%
  }

  .textTypeInput {
    height: 30px;
    /* width: 100%; */
    font-size: 13px;
  }


  .bodyMain_cont {
    /* border: 1px solid olive; */
    width: 90%;
    padding: 10px;
  }

  .addVariableBtn {
    height: 30px;
    padding: 4px 8px;
  }

  .templateMsgMain_cont {
    /* border: 1px solid blue; */
    height: auto;
  }

  .templateMsgInput {
    padding: 0;
    /* border: 2px solid red; */
    width: 100%;
  }


  .footerMain_cont {
    /* border: 1px solid olive; */
    width: 90%;
    padding: 10px;

  }

  .footerInputMain_cont {
    /* border: 1px solid green; */
    width: 80%;
    width: 99%;
  }


  .buttonsMain_cont {
    /* border: 1px solid olive; */
    width: 90%;
    height: auto;
    padding: 10px;
    margin: 15px auto;
  }

  .callToAction_cont {
    /* border: 1px solid blue; */
    width: 100%;
    height: auto;

  }

  .buttons_cont {
    /* border: 1px solid red; */
    width: 100%;
  }

  .callToAction_cont h6 {
    margin: 10px 0;
  }

  .buttonTextInput {
    height: 30px;
    width: 100%;
    margin-bottom: 10px;

  }

  .callToActionInput {
    height: 30px;
    width: 100%;

  }

  .quickReplyCont {
    /* border: 1px solid green; */
  }

  .quickReplyCont h6 {
    margin: 10px 0;
  }

  .quickReplyInputs_cont {
    display: block;
  }

  .quickReplyInput {
    height: 30px;
    width: 100%;
    margin-bottom: 10px;
  }

  .submitBtn_cont {
    /* border: 1px solid green; */
    width: 85%;
    height: auto;
    padding: 10px;
    margin: 0px auto;
    margin-bottom: 20px;
    justify-content: center;
  }

  .submitBtn {
    margin: 0;
  }

  .mainOutput_cont {
    display: none;
  }







}