.segmentMain_cont{
    /* border: 2px solid red; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    overflow-y: scroll;
    padding: 20px;
    /* padding-top: 10px; */
    padding-bottom: 30px;
    scrollbar-width: none;
}

.segmentMain_cont::-webkit-scrollbar {
  display: none;
}

.newSegmentBtn_cont{
  /* border: 1px solid blue; */
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.presentSegmentMain_cont{
  /* border: 2px solid green; */
  height: auto;
  padding-top: 20px;
  display: flex;
  justify-content: center;

}
.presentSegments_cont{
  /* border: 1px solid purple; */
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.actionsButtonCont{
  display: flex;
}
