@import "../../App.css";

#Main_cont{
  /* border: 2px solid red; */
  display: flex;
  height: 100vh;
  width: 100%;
}

#main_cont{
  height: 100vh;
  width: 100%;
  scrollbar-width: none;
}

#main_cont::-webkit-scrollbar{
  display: none;
}


.MaintoolbarCont{
  /* border: 1px solid blue; */
  height: 12vh;
}
.MainMainCont{
  /* border: 1px solid green; */
  display: flex;
  width: 100%;
  height: 88vh;
}
.MainSideBarCont{
  /* border: 1px solid red; */
  width: 18%;
}
.MainContentCont{
  /* border: 1px solid orange; */
width: 82%;
height: 85vh;
height: 100%;
}



.sidebarCont{
  /* border: 1px solid blue; */
  width: 18%;
}
.mainCont{
  /* border: 2px solid green; */
  width: 82%;
  height: 100%;
}

.toolbarCont{
  /* border: 2px solid slateblue; */
  height: 15vh;

}
.contentCont{
  /* border: 2px solid orange; */
  height: 85vh;
}


.speed-dial-button {
  position: fixed;
  bottom: 25px;
  right: 20px;
  background-color: var(--primary-color);
  color: white;
  font-size: 36px;
  border: none;
  border-radius: 50%;
  padding: 20px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: 55px;
  width: 55px;
}

.speed-dial-button:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.speed-dial-button:focus {
  outline: none;
}

.speed-dial-button img{
  background-color: var(--primary-color);
}


/* Styling for the chat container */
.chat-container {
  position: fixed;
  bottom: -300px;
  right: 20px;
  width: 350px;
  height: 450px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
  background-color: #EEEEEE;
  border: 1px solid var(--primary-color);
}

/* When the chat container is visible, slide it into view */
.chat-container.visible {
  bottom: 20px;
  /* Bring it up 20px from the bottom */
  opacity: 1;
  visibility: visible;
}

/* Styling for the close button inside the chat container */
.closeChatBtn {
  position: absolute;
  top: -55px;
  right: 10px;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  transition: color 0.2s ease;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: var(--primary-color);
}

.closeChatBtn:hover {
  /* transform: scale(1.04); */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}


@media (max-width: 480px) {
  #Main_cont{
    display: block;
  }
}