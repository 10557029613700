.loginReqMain{
  /* border: 1px solid blue; */
  padding: 10px 20px;
}

.loginReqClose_cont{
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
}

.loginReqClose{
  border: 2px solid gray;
  border-radius: 5px;
}

.loginReqContent_cont{
  /* margin-top: 30px; */
}

.loginReqContent_cont p{
  color: gray
}

.goToLoginBtn_cont{
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}

.goToLoginBtn{
  border: 1px solid black;
  border-radius: 10px;
  text-decoration: none;
  color: #fff;
  background-color: black;
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.3s;
}

.goToLoginBtn:hover{
  border: 1px solid black;
  background-color: #fff;
  color: black;
}