@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.whatsapp_main{
    /* border: 2px solid red; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    overflow-y: scroll;
    padding: 20px;
    /* padding-top: 10px; */
    padding-bottom: 30px;
    scrollbar-width: none;
}

.whatsapp_main::-webkit-scrollbar{
    display: none;
}

.whatToolMain_cont{
    /* border: 1px solid blue; */
    border-radius: 10px;
    background-color: #EEEEEE;
    margin-bottom: 30px;
    height: 10%;
    padding: 0px 30px;
    display: flex;
    align-items: center;
}

.whatToolsBtns_cont{
    /* border: 1px solid blue; */
    width: auto;
    display: flex;
}

.whatToolsLink{
    /* border: 1px solid blue; */
    margin-right: 25px;
    text-decoration: none;
    color: rgb(103, 116, 142);
    font-size: 15px;
    display: flex;
    align-items: center;
    background-color: transparent;
}

.whatToolsLink:hover{
    color: var(--primary-color);
}

.whatsapptabActive {
    color: var(--primary-color);
    cursor: pointer;
}

.customNavLink {
    color: black;
    /* Default color */
    text-decoration: none;
}

.customNavLink:hover {
    color: darkgray;
    /* Color on hover */
}

.customNavLink.activeLink {
    color: green;
    /* Active link color */
}

.searchAndbtn_cont {
    /* border: 2px solid green; */
    width: 100%;
    margin: auto;
    padding: 0px 40px;
}

.searchMain_cont{
    /* border: 2px solid purple; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.searchTemplate_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.searchSortTemplate_cont{
    /* border: 1px solid red; */
    width: 60%;
    display: flex;
}

.searchTemplateInput{
    width: 50%;
}

.sortTemplateInput{
    width: 30%;
    margin-left: 20px;
}

.tableMain_cont{
    /* border: 2px solid red; */
    height: auto;
    /* padding: 20px; */
    padding-top: 20px;
    /* overflow: auto;
    overflow-y: hidden; */
    display: flex;
    justify-content: center;
    
}

.table_cont {
    /* border: 2px solid blue; */
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.actionsButtonsCont{
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-between;
    width: auto;
}

.broadcastBtn_cont{
    border-radius: 5px;
    border: 1px solid green;
    color: green;
    height: 30px;
    width: auto;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    margin: auto;
    margin-left: 15px;
}

.broadcastBtn_cont:hover {
    background-color: green;
    color: #fff;
}

.editBtn_cont{
    border-radius: 5px;
    background-color: #d4f4e6;
    border: 1px solid #78d88b;
    color: #78d88b;
    height: 30px;
    width: 30px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    cursor: pointer;
}
.editBtn_cont:hover{
    /* background-color:  green;
    background-color: #d4f4e6; */
    border: 1px solid #78d88b;
    color: #fff;
}


.deleteBtn_cont{
    border-radius: 5px;
    background-color: #FFE4C0;
    border: 1px solid #FD5D5D;
    color: #FD5D5D;
    height: 30px;
    width: 30px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    cursor: pointer;
    
}
.deleteBtn_cont:hover {
    color: #fff;
}
