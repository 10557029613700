.unAuthMain_cont {
  /* border: 1px solid blue; */
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: gray;
}

.unAuthNavbar {
  /* border: 1px solid blue; */
  height: 15%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 60px;
}

.unAuthSub_cont {
  /* border: 1px solid red; */
  height: 75%;
  width: 80%;
}

.unAuthImg {
  height: 100%;
  width: 100%;
}

.unAuthHead_cont {
  /* border: 1px solid green; */
  height: 10%;
}