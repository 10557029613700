.datasetFromMain{
  /* border: 3px solid blue; */
}

.newDatasetHeader{
  padding: 20px 30px;
  border-radius: 10px;
  display: flex;
  background-color: #fff;
  justify-content: space-between;
}

.createDatasetMain_cont {
  /* border: 2px solid green; */
  height: 440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f8f9fa;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;
}

.createDatasetMain_cont::-webkit-scrollbar {
  display: none;
}

.datasetNameInput_cont {
  width: 100%;
  padding: 0px 20px;
  display: flex;
  /* border: 1px solid olive; */
}

.datasetHeadingInputCont {
  /* border: 1px solid blue; */
  width: 65%;
  display: flex;
}

.datasetNameHeading {
  min-width: 25%;
  width: 30%;
  height: 100%;
  /* margin-right: 20px; */
  display: flex;
  align-items: center;
  /* border: 1px solid blue; */
}

.datasetNameBox {
  /* border: 1px solid purple; */
  display: flex;
  align-items: center;
  width: 65%;
  margin-right: 10px;
}

.datasetDesc_cont {
  margin-top: 20px;
  width: 100%;
  padding: 0px 20px;
  display: flex;
}

.datasetInputsMain_cont {
  /* border: 1px solid blue; */
  margin-top: 20px;
}

.newDatasetRow {
  /* border: 1px solid red; */
  display: flex;
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.newDatasetFormInput_cont {
  /* border: 1px solid blue; */
  width: 100%;
  height: 100%;
}

.ageRangeCont{
  /* border: 1px solid blue; */
  display: flex;

}