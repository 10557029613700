.noMarketplace_cont {
  /* border: 1px solid blue; */
  height: 80vh;
  text-align: center;
  margin: auto;
}

.noMarketImg_cont {
  /* border: 1px solid red; */
  height: 65%;
  text-align: center;
}

.noMarketImg_cont img {
  height: 100%;
}

.noMarketplaceHead_cont {
  color: gray;
  text-align: center;
}

.noMarketplace_cont p {
  width: 90%;
  margin: auto;
}

.integrateNewBtn_cont {
  margin-top: 20px;
}