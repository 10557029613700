.buyerMain {
  /* border: 2px solid red; */
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  overflow-y: scroll;
  padding: 20px;
  /* padding-top: 10px; */
  padding-bottom: 30px;
  scrollbar-width: none;
}


.buyerRow1{
  width: 100%;
  height: auto;
  display: flex;
}


.buyerRow1Card{
  border: 1px solid lightgray;
  border-radius: 10px;
  width: 20%;
  height: auto;
  margin-right: 10px;
  padding: 8px;
}

.buyerCardSub_cont{
  /* border: 1px solid blue; */
  border-radius: 10px;
  width: 100%;
  height: auto;
  padding: 8px;
}

.buyerCardSub_cont1{
  width: 100%;
  display: flex;
}

.buyerCardIcon_cont{
  /* border: 1px solid red; */
  width: 50%;
}

.buyerCardData_cont{
  /* border: 1px solid blue; */
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.buyerCardData{
  border-radius: 50%;
  background-color: #fff;
  /* width: 100%; */
  padding: 8px;
  width: 50px;
  height: 50px;
}

.buyerCardSub_cont2{
  margin: 10px 0px;
  font-size: 18px;
  font-weight: bold;
}

.buyerCardBtn_cont{
  margin-top: 10px;
  height: auto;
  display: flex;
  justify-content: flex-end;
}

.buyerCardBtn{
  border: 1px solid black;
  background-color: black;
  color: #fff;
  border-radius: 20px;
  padding: 4px 15px;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
  width: 100%;
  text-align: center;
}

.buyerCardBtn:hover{
  border: 1px solid black;
  background-color: transparent;
  color: black;
}






.buyerRow2{
  /* border: 1px solid blue; */
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 20px;
}

.buyerRow2Card{
  /* border: 1px solid lightgray; */
  border-radius: 10px;
  width: 20%;
  height: 130px;
  margin-right: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}

.dashLiveOfferMain_cont{
  /* border: 1px solid red; */
  width: 100%;
  height: auto;
  margin-top: 20px;
}


.dashLiveBtn_cont{
  /* border: 1px solid green; */
  display: flex;
}

.dashLiveBtn{
  height: 30px;
  font-weight: 500;
  font-size: 13px;
  min-width: 60px;
  padding: 1px 10px;
}

