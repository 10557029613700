@import '../../App.css';

.termsMain_cont{
  /* border: 1px solid blue; */
  height: 100%;
  height: 100vh;
  width: 100%;
  
}

.termsTool_cont{
  height: 12%;
}

.termsInner_cont{
  background-color: #c7cdff;
  padding: 20px;
  height: 88%;

}

.termsSub_cont{
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  overflow: auto;
  overflow-y: scroll;
   padding-bottom: 30px;
   scrollbar-width: none;
    background-image: url(../../assets/termsBack.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}


.termsMainHead{
  font-size: 24px;
  text-align: center;
}

.terms_cont{
  /* border: 1px solid red; */
  margin: 10px 0px;
}

.terms_cont p{
  margin-bottom: 10px;
  font-size: 14px;
}

.termsBoldPara{
  font-weight: 700;
  margin-top: 20px;
  font-size: 20px !important;
}
