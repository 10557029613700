@import '../../../../../App.css';

.submitContactMain_cont{
  /* border: 2px solid blue; */
  height: 100%;
  width: 100%;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
}

.contactsFileMain_cont{
  /* border: 1px solid blue; */
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 60%;
  margin: auto;
  margin-top: 20px;
  padding: 20px;
}

.contactsFileMain_cont p{
  font-size: 14px;
}
.dragDropMain_cont{
  border: 1px dashed blue;
  border-radius: 10px;
  padding: 20px 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  height: auto;

}
.uploadContactFileLabel {
  display: inline-block;
  background: none;
  padding: 0px 5px;
  border-radius: 10px;
  cursor: pointer;
  border-width: 1px;
  color:var(--secondary-color);
  transition: box-shadow 0.3s ease 0s;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-decoration: underline;
}

.uSubmit_cont{
  /* border: 1px solid blue; */
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

