.newLaunchMain {
  /* border: 2px solid red; */
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  overflow-y: scroll;
  padding: 20px;
  /* padding-top: 10px; */
  padding-bottom: 30px;
  scrollbar-width: none;
}

.newLaunchMain::-webkit-scrollbar {
  display: none;
}