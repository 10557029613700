.supplier_main{
  /* border: 2px solid darkgoldenrod; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  scrollbar-width: none;
}
.supplier_main::-webkit-scrollbar{
  display: none;
}


@media (max-width: 480px) {
  .supplier_main {
    width: 100%;
  }
}