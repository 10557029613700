@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

@import '../../App.css';
.toolbarMain_cont {
  /* border: 2px solid blue; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 60px;
  padding-left: 0px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.toolbarBrandMain_cont{
  /* border: 1px solid blue; */
  width: 19%;
  height: 100%;
}

.toolBrandImg_cont{
  /* border: 1px solid red; */
  width: auto;
  height: 40px;
}

.toolBrandImg_cont img{
  width: auto;
  height: 100%;
}

.toolbarBrandName{
  font-size: 38px;
  font-weight: 800;
}

.toolsMain_cont {
  /* border: 2px solid green; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: auto;
}

.localesMain_cont{
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
}



.twallet_cont {
  /* border: 1px solid blue; */
  background-color: #EEEEEE;
  border-radius: 50%;
  margin-left: 20px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  position: relative;
}

.walletPopup_cont {
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  background-color: white;
  width: 400px;
  /* height: 200px; */
  padding: 20px;
  position: absolute;
  top: 12vh;
  top: 120%;
  right: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.walletPopup_cont p{
  font-size: 14px;
}

.walletPopupBtnRow{
  margin-top: 20px;
  display: flex;
}

.walletPopupBtnRow button{
  width: 50% !important;
}

.tnotification_cont{
  /* border: 1px solid blue; */
  background-color: #EEEEEE;
  border-radius: 50%;
  margin-left: 20px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  position: relative;
}

.tsignIn_cont {
  border: 2px solid  skyblue;
  border-radius: 50%;
  margin-left: 20px;
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;
}

.userProfileImg{
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.dropdownMenu{
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  background-color: white;
  width: 160px;
  height: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 120%;
  right: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.tDropdownMenuLink{
  /* border: 1px solid blue; */
  border-radius: 10px;
  text-decoration: none;
  color: rgb(52, 71, 103);
  display: flex;
  padding: 10px;
  align-items: center;
  cursor: pointer;
}

.profileIcon_cont{
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tDropdownMenuName{
  font-size: 14px;
}

.tDropdownMenuLink:hover{
  background-color: #F6F5F2;
}

.settingsLinkCont{
  margin-bottom: 5px;
}

.tDropdownHR{
  border-top: none;
}

.logoutLinkCont{
  margin-top: 5px;
}


.notiPopup_cont{
/* border: 1px solid #ccc; */
  border-radius: 5px;
  background-color: white;
  width: 350px;
  height: 500px;
  padding: 20px;
  position: absolute;
  top: 12vh;
  top: 120%;
  right: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}