.profileMain_cont{
  /* border: 1px solid blue; */
  padding: 30px 100px;
  height: 100%;
  width: 100%;
}

.profileSub_cont{
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  height: 100%;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profileHeader_cont{
  /* border: 1px solid red; */
  border-radius: 10px;
  display: flex;
  height: 120px;
  
}

.profilePhoto_cont{
  /* border: 1px solid green; */
  height: 100%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.profilePhoto{
  border: 1px solid lightgray;
  height: 100%;
  width: 120px;
  border-radius: 50%;
}

.userProfilePhoto{
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.profileName_cont{
  /* border: 1px solid blue; */
  height: 100%;
  width: 70%;
}

.editProfileBtn_cont{
  /* border: 1px solid blue; */
  display: flex;
  justify-content: flex-end;
}

.profileUserName{
  font-size: 20px;
  font-weight: 700;
}

.profileUserDesc{
  font-size: 15px;
  color: gray;
}

.profileHr{
  margin-top: 20px;
  /* border-top: 0px; */
  border-bottom: 0px;
}

.profileData_cont{
  border: 1px solid lightgray;
  border-radius: 10px;
  margin-top: 30px;
  padding: 20px;
  height: 150px;
  width: 100%;
}

.profileDataHeader_cont{
  height: 20%;
  font-size: 18px;
  font-weight: 700;
}

.profileDataSub_cont{
  /* border: 1px solid red; */
  height: 80%;
  display: flex;
}

.profileDataSub_cont{
}

.profileData1{
  /* border: 1px solid green; */
  height: 100%;
  width: 100%;
  padding: 20px;
  text-align: center;
}

.profileData2{
  /* border: 1px solid green; */
  height: 100%;
  width: 100%;
  padding: 20px;
  text-align: center;
}

.profileData3{
  /* border: 1px solid green; */
  height: 100%;
  width: 100%;
  padding: 20px;
  text-align: center;
}

.profileDataParaHeader{
  font-size: 17px;
  color: gray;
}