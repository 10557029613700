.viewBroadcastMain_cont{
  /* border: 2px solid green; */
    height: 100%;
    width: 100%;
    overflow: auto;
    overflow-y: scroll;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 30px;
    scrollbar-width: none;
}