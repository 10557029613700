@import '../../../../App.css';

.startImportMain_cont{
  /* border: 2px solid green; */
  height: 100%;
  width: 100%;
  padding: 20px;
    padding-top: 10px;
    padding-bottom: 30px;
}

.startImportMain{
  /* border: 1px solid red; */
  height: 64%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.submitContactLink{
  text-decoration: none;
  color: black;
}
.startImportCard{
  /* border: 1px solid blue; */
  cursor: pointer;
}

.startImportCard:hover{
  box-shadow: 0 16px 20px rgba(0, 0, 0, 0.1) ;
}

.startImportContactHelp_cont{
  /* border: 1px solid blue; */
    padding: 10px;
    height: 90px;
    width: 70%;
    margin: auto;
    margin-top: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}
.startImportHelpCont1{
  /* border: 1px solid blue; */
  width: 100%;
  height: 50%;
}

.startImportHelpCont2{
  /* border: 1px solid green; */
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.helpAnchorCont{
  display: flex;
  /* border: 1px solid red; */
  margin-right: 20px;
  font-size: 14px;
}

.helpAnchor{
  display: flex;
  align-items: center;
  margin-right: 5px;
  color: var(--secondary-color);
  /* text-align: center; */
}