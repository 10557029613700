.notFoundMain_cont{
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: gray;
}

.notfoundNavbar {
  height: 15%;
  width: 100%;
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 60px;
}

.notFoundSub_cont{
  /* border: 1px solid red; */
  height: 75%;
  width: 80%;
}

.notFoundImg{
  height: 100%;
  width: 100%;
}

.notFoundHead_cont{
  /* border: 1px solid green; */
  height: 10%;
}