


.priceLocationMain_cont{
  /* border: 1px solid blue; */
  display: flex;
  justify-content: space-between;
}

.priceAnalyticsMinMax_cont{
  /* border: 1px solid red; */
  width: 30%;
  display: flex;
  margin-right: 10px;
}
.priceCompare_cont{
  /* border: 1px solid red; */
  width: 25%;
  margin-right: 10px;
}

.priceMatch_cont{
  /* border: 1px solid red; */
  /* border: 1px solid lightgray; */
  border-radius: 4px;
  width: 15%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;

}

.priceMatchHead_cont{
  /* border: 1px solid green; */
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.priceMatchBtn_cont{
  /* border: 1px solid green; */
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.priceMismatchHead{
  font-size: 12px;
}

.priceAnalyticsSubmit_cont{
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.filterSubmitBtn{
  border: 1px solid rgb(23, 193, 232);
  border-radius: 5px;
  color: rgb(23, 193, 232);
  background-color: #fff;
  padding: 10px 20px;
  transition: 0.3s;
}

.filterSubmitBtn:hover{
  background-color: rgb(23, 193, 232);
  color: #fff;
  border: 1px solid rgb(23, 193, 232);
}

.analyticsViewBtn {
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  background-color: #fff;
  padding: 10px;
  color: var(--primary-color);
  cursor: pointer;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 1px 10px;
  transition: 0.3s ease;
}

.analyticsViewBtn:hover {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: #fff;
  transition: 0.3s;
  border-radius: 10px;

}