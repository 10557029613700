.importContactMain_cont{
  /* border: 2px solid green; */
    height: 100%;
    width: 100%;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 30px;
}

.importContactHeading_cont{
  /* border: 1px solid blue; */
  margin-bottom: 0px;
  color: #344767;
}

.importContactMain{
  /* border: 1px solid blue; */
  /* height: 360px; */
  height: 64%;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.importOneMain{
  /* border: 1px solid red; */
  height: 100%;
  width: 38%;
  padding: 20px 40px;
  padding-bottom: 0px;

}
.importCardOne_cont{
  /* border: 1px solid green; */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  height: 100%;
  width: 100%;
}

.importTwoMain {
  /* border: 1px solid red; */
  height: 100%;
  width: 38%;
  padding: 20px 40px;
  padding-bottom: 0px;
}

.importCardTwo_cont {
  /* border: 1px solid green; */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 100%;
  width: 100%;
  padding: 20px;
}

.importImgCont{
  /* border: 1px solid blue; */
  height: 110px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.importContactImg{
  height: 110px;
  width: 130px;
}

.importContactH5{
  text-align: center;
}
.importContactPara{
  text-align: center;
  margin-top: 10px;
}

.importContactBtn_cont{
  /* border: 1px solid blue; */
  text-align: center;
  margin-top: 20px;
}

.importContactHelp_cont{
  /* border: 1px solid blue; */
  padding: 10px 0px;
  height: 90px;
  width: 60%;
  margin: auto;
  margin-top: 20px;
  display: flex;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}
.helpIcon_cont{
  /* border: 1px solid purple; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
}
.helpContent_cont{
  /* border: 1px solid purple; */
  width: 80%;
}
.helpPara{
  font-size: 14px;
}
.helpCross_cont{
  /* border: 1px solid purple; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
}
