.newTextSubCont{
  /* border: 1px solid blue; */
  height: auto;
  padding: 0px 20px;
  
}
.newTextFormInput_cont{
  margin-top: 10px;
  
}

.newTextBtn_cont{
  /* border: 1px solid blue; */
  height: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}