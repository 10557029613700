.importGuideMain_cont{
  /* border: 2px solid blue; */
    height: 100%;
    width: 100%;
    padding: 20px;
    padding-top: 30px;
    padding-top: 10px;
    padding-bottom: 30px;
}

.importGuide_cont{
  /* border: 2px solid green; */
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 40px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}