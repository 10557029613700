.buyProductMain{
  /* border: 1px solid blue; */
  width: 100%;
  height: 100%;
  padding: 20px;
}

.buyProductSub_cont{
  /* border: 1px solid red; */
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
}

.buyProductSub_cont1{
  /* border: 1px solid green; */
  border-radius: 10px;
  background-color: #1a1a1a;
  color: #fff;
  padding: 20px;
  /* background: linear-gradient(to right, #000000, #333333); */
  width: 50%;
  height: 100%;
  margin-right: 20px;
}

.buyProductSub_cont1 p{
  margin-top: 10px;
}

.buyProductSub_cont2{
  border: 1px solid lightgray;
  border-radius: 10px;
  width: 50%;
  height: 100%;
  padding: 20px;
  padding-top: 10px;
}

.buyerCloseModal_cont{
  /* border: 1px solid blue; */
  display: flex;
  justify-content: flex-end;
  
}

.buyProductForm{
  /* border: 1px solid red; */
}

.buyProductFields{
  width: 100%;
}

.buyProductSub_cont2 h3{
  margin-bottom: 10px;
}

.buyProductSub_cont2 p{
  margin-top: 10px;
  font-weight: 500;
  font-size: 15px;
}

.buyProductBtn_cont{
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}