.ebayAuthMain_cont {
  /* border: 1px solid blue; */
  padding-top: 50px;
  height: 100%;
  width: 100%;
}

.ebayAuthSub_cont1 {
  /* border: 1px solid red; */
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  height: 70%;
  width: 50%;
  margin: 0px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ebayAuthSub_cont1 p {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}

.ebayAuthSub_cont2 {
  /* border: 1px solid purple; */
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  height: 70%;
  width: 50%;
  margin: 0px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ebayAuthSub_cont2 p {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}

.ebayAuthImg_cont {
  /* border: 1px solid green; */
  height: 50%;
  width: 100%;
}

.ebayAuthImg_cont img {
  height: 100%;
  /* width: 100%; */
}

.ebayAuthImg_cont {
  opacity: 0;
  /* Start hidden */
  transform: scale(0.8);
  /* Start slightly smaller */
  transition: opacity 0.5s ease, transform 0.5s ease;
  /* Transition properties */
  animation: none;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-12px);
    /* Move up */
  }

  60% {
    transform: translateY(-5px);
    /* Move down a little */
  }
}

.ebayAuthImg_cont.show {
  opacity: 1;
  /* Fully visible */
  transform: scale(1);
  /* Normal size */
  animation: bounce 3s ease infinite;
}

.ebayAuthBtn {
  width: 100%;
  margin-top: 20px;
  font-size: 16px;
}