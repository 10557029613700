@import '../../../App.css';

.createBroadcastMain_cont {
  /* border: 2px solid blue; */
  height: 100%;
  width: 100%;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
  overflow: auto;
    overflow-y: scroll;
    scrollbar-width: none;
}

.createBroadcastMain_cont::-webkit-scrollbar {
  display: none;
}
.createBroadcast_cont {
  /* border: 2px solid green; */
  width: 100%;
  height: auto;
  margin: auto;
  padding: 30px 40px;

  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.createBroadcastRow{
  /* border: 1px solid blue; */
  height: auto;
  margin-bottom: 8px;
  display: flex;
}

.createBroadcastInput_cont{
  /* border: 1px solid red; */
  width: 100%;
}

.createBroadcastQuote_cont{
  /* border: 1px solid green; */
}

.broadcastRow_cont1{
  /* border: 1px solid blue; */
height: 100%;
width: 100%;
margin-right: 20px;
}

.broadcastRow_cont2{
  /* border: 1px solid blue; */
  height: 100%;
  width: 100%;
}

.uploadQuoteLabel{
  padding: 1px 12px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 10px;
  background-color: var(--primary-color);
  color: #fff;
  font-size: 13px;
  transition: box-shadow 0.3s ease 0s;
  width: auto;
  height: 2.2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

/* .uploadQuoteLabel:hover{
  background-color: #fff;
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
} */

.createBroadCastFileLabel_cont{
  border: 1px solid #afb3b7;
  border-radius: 5px;
  height: auto;
  height: 56px;
  padding: 16.5px 14px;
  display: flex;
  align-items: center;
}

.selectedQuoteFile_cont{
  /* border: 1px solid green; */
  height: auto;
  width: auto;
  font-size: 14px
}

.selectedContactFile_cont{
  height: auto;
  width: auto;
  font-size: 14px;
}

.processBroadcastBtn_cont{
  /* border: 1px solid blue; */
  margin-top: 20px;
  height: auto;
  display: flex;
  justify-content: space-between;
}

.createBroadcastHelpLinks_cont{
  /* border: 1px solid red; */
  background-color: #ECF2FF;
  border-radius: 10px;
  height: 42px;
  width: 60%;
  display: flex;
  justify-content: center;
}


.quoteFileDataMain_cont{
  /* border: 1px solid gray; */
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
  height: auto;
  margin-top: 30px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.quoteFileDataMain_cont p{
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.quoteTableHead_cont{
  /* border: 1px solid blue; */
  background-color: #e2eefe;
  font-weight: 700;
  width: 100%;
  display: flex;
  padding: 10px 20px;
  border-radius: 10px;
  
}

.quoteTableHead_col{
  /* border: 1px solid green; */
  width: 100%;
  min-width: 100px;
  max-width: 200px;
}

.quoteTableRow{
  /* border: 1px solid red; */
  background-color: #e2eefe;
  width: 100%;
  display: flex;
  margin-top: 5px;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.quoteTableBody_col{
  /* border: 1px solid green; */
  width: 100%;
  min-width: 100px;
  max-width: 200px;
}

.dealerPrice {
  border: 1px solid rgb(35, 164, 85);
  border-radius: 10px;
  padding: 5px;
  width: 100px;
}

.createBroadcastBtn_cont{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.dealerAlert_cont{
  width: 60%;
}

.totalRow{
  margin-top: 20px;
  border: 1px solid blue;
}