@import '../../App.css';
.myAccountMain_cont{
  /* border: 1px solid blue; */
  height: 100%;
  width: 100%;
  padding: 20px;
  overflow: auto;
    overflow-y: scroll;
    scrollbar-width: none;
}

.myAccountSub_cont1{
  border: 1px solid lightgrey;
  border-radius: 10px;
  height: auto;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.companyLogoMain_cont{
  /* border: 1px solid blue; */
  width: 40%;
  height: 100%;
  margin: auto 0px;
}
.companyLogo_cont{
  /* border: 1px solid red; */
  height: 80px;
  width: auto;
  margin-top: 10px;
}

.companyLogo_cont img{
  height: 100%;
  width: auto;
}

.changeLogoBtn_cont{
  /* border: 1px solid green; */
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}


.changeLogoBtn {
  background-color: transparent;
  padding: 1px 10px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  min-width: 130px;
  width: auto;
  height: 2.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.changeLogoBtn:hover {
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
}

.changeLogoBtn:active {
  transform: scale(0.95);
}

.changeLogoBtn:focus {
  outline: none;
}


.logoErrors_cont{
  margin-top: 10px;
  text-align: center;
}

.myAccountFormCont{
  /* border: 1px solid blue; */
  width: 50%;
  height: 100%;
}

.myAccountFormRow{
  display: flex;
}

.myAccInput_cont {
  /* border: 1px solid blue; */
  width: 100%;
  height: 100%;
  margin-bottom: 5px;
}

.myAccInput_cont label{
  font-size: 14px;
}

.myAccInput{
  width: 100%;
}

.myAccFormBtn_cont{
  /* border: 1px solid blue; */
  display: flex;
  justify-content: flex-end;
}



.accPriceHeaderMain_cont{
  /* border: 1px solid green; */
  height: auto;
  display: flex;
}

.accPriceHeaderCol1{
  /* border: 1px solid red; */
  width: 40%;
  height: auto;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.accPriceHeaderCol1 p{
  font-weight: 800;
  color: var(--accent-color);
}

.accFilterCont{
  border: 1px solid gray;
  border-radius: 10px;
  background-color: #fff;
  height: 60px;
  width: 50%;
  margin: 20px auto;
  margin-bottom: 0px;
  padding: 10px;
  display: flex;
  color: var(--accent-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.monthTab {
  /* border: 1px solid green; */
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  color: black;
  width: 50%;
  height: 100%;
  margin-right: 10px;
  text-decoration: none;
  cursor: pointer;
  transition: 0.4s;
}

.yearTab {
  /* border: 1px solid green; */
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  color: black;
  width: 50%;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
  transition: 0.4s;
}

.monthTabActive {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: #fff;

}


.plansOfferCont{
  border-radius: 10px;
  background-color: #d4f4e6;
  height: 35px;
  margin-top: 25px;
  font-size: 12px;
  color: green;
}

.accPriceHeaderCol{
  /* border: 1px solid purple; */
  width: 20%;
  height: auto;
  padding: 20px;
  padding-top: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.accPriceHeaderCol h2{
  color: var(--accent-color);
  color: var(--secondary-color);
  font-size: 22px;
  margin-bottom: 5px;
}

.accPriceHeaderCol p{
  font-size: 14px;
  margin-bottom: 10px;
}

.accPriceHeaderCol p span{
  font-size: 18px;
  color: var(--secondary-color);
}
.accountPricePara{
  color: darkslategray;
  margin-top: 10px;
}

.accountYearPricePara {
  color: gray !important;
}
.accountYearPricePara span{
  color: gray !important;
}

.strikethrough{
  text-decoration: line-through;
}

.strikethrough::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 2px;
  /* Thickness of the line */
  background-color: black;
  /* Color of the line */
  transform: translateY(-50%);
  /* Vertically center the line */
}

.choosePlanBtn{
  text-decoration: none;
  margin-top: auto;
}


.pricingTableCont {
  /* border: 1px solid blue; */
}

.accPriceCatHeader{
  /* border: 1px solid blue; */
  border-top: 1px solid lightgrey;
  display: flex;
}

.accPriceCatName{
  /* border: 1px solid green; */
  width: 40%;
  padding: 10px;
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  padding-top: 30px;
}

.priceRow{
  /* border: 1px solid brown; */
  display: flex;
  line-height: 24px;
  font-size: 15px;
}

.featureNameCont{
  /* border: 1px solid green; */
  width: 40%;
  padding: 10px;
  color: var(--accent-color);
  
}

.accOption{
/* border: 1px solid orangered; */
width: 20%;
padding: 10px;
}


