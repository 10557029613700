.integration_main{
  /* border: 2px solid violet; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  scrollbar-width: none;
}

.integration_main::-webkit-scrollbar{
display: none;
}

@media (max-width: 480px) {
  .integration_main {
    width: 100%;
  }
}