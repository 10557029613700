@import '../../App.css';

.sidebarMain_cont{
  /* border: 1px solid blue; */
  border-right: 1px solid lightgray;
  justify-content: center;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  scrollbar-width: none;
  overflow: auto;
  overflow-y: scroll;
}

.sideButtonsMain_cont{
  /* border: 2px solid green; */
  height: auto;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-behavior: smooth;
}
.sideButtonLink_cont{
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  border-radius: 10px;
  margin-bottom: 10px;
}
.sideNavLink{
  /* border: 1px solid green; */
  border-radius: 10px;
  text-decoration: none;
  width: 100%;
  height: 100%;
  padding: 5px 0px;
  display: flex;
  align-items: center;

}
.sideNavLink:hover{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.active{
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sideButton_cont {
  /* border: 1px solid purple; */
  padding: 8px;
  margin: 1px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 0;
  border-radius: 10px;
  cursor: pointer;
}

.buttonIcon_cont{
  /* border: 1px solid red; */
  background-color: #fff;
  min-height: 2rem;
  min-width: 2rem;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-right: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sideButtonName{
  letter-spacing: 0.00938em;
  color: var(--accent-color);
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  font-size: 1rem;
}

.sidebarLinkArrow_cont{
  /* border: 1px solid blue; */
    color: rgb(52, 71, 103);
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.salesSubLink_cont {
  /* border: 1px solid blue; */
  border: 1px solid lightgray;
  border-radius: 10px;
  height: auto;
  width: 90%;
  padding: 0px 15px;
  /* padding-right: 0px; */
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
}

.sidebarLinksInner_cont{
  /* border: 1px solid green; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.salesSubLink_cont.visible {
  max-height: 500px;
  opacity: 1;
}

.sideSubNavLink {
  /* border: 1px solid blue; */
  text-decoration: none;
  width: 100%;
  height: auto;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.sideSubNavLink:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.sideSubButton_cont {
  /* border: 1px solid blue; */
  padding: 6px 8px;
  margin: 1px;
  height: auto;
  /* width: 100%; */
  display: flex;
  align-items: center;
  line-height: 0;
  border-radius: 10px;
  cursor: pointer;
}

.sideSubButtonName{
  letter-spacing: 0.00938em;
  color: var(--accent-color);
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  font-size: 0.9rem;
}

@media (max-width: 480px){
  .sidebarMain_cont{
    /* border: 2px solid red; */
    /* display: none; */
    position: absolute;
    top: 10vh + 40px;
    width: 70vw;
    transform: translate(-80vw);
    transition: transform 0.3s;
  }
  .mobileNavbar_cont{
    display: flex;
    justify-content: space-between;
  }
  .mobProfileMain_cont{
    display: flex;
  }
  .sideButtonLink_cont{
    height: 60px;
  }
  


}