.editRoleMain_cont{
  /* border: 1px solid blue; */
}

.editRoleForm_cont {
  /* border: 1px solid blue; */
  border-radius: 10px;
  height: auto;
  padding: 10px 20px;
  padding-bottom: 20px;
  /* background-color: #f8f9fa; */
}