.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  /* Full height of the viewport */
  overflow: hidden;
  border-radius: 10px;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: white;
}

.slide.active {
  opacity: 1;
  /* Fade in the active slide */
}