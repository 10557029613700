@import '../../../App.css';

.welcomeNavbarMain_cont {
  /* border: 1px solid green; */
  color: #fff;
  display: flex;
  justify-content: space-between;
  height: 12vh;
  width: 100%;
  padding: 0px 6%;
  /* padding: 0px 60px; */
  border-bottom: 1px solid lightgray;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: var(--background-color);
  background-color: #fff;
}

.welcomeNavbarBrand_cont {
  /* border: 1px solid brown; */
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  text-decoration: none;
}

.toolbarBrandName{
  text-decoration: none !important;
  color: black;
}

.welcomeLinkAndBtn_cont {
  /* border: 1px solid blue; */
  width: 65%;
  width: auto;
  display: flex;
  height: 100%;
}

.todaysDealsLink_cont{
  /* border: 1px solid blue; */
  margin-right: 40px;
}

.todaysDealsLink{
  padding: 10px;
  border: 1px solid red;
  color: red;
  border-radius: 10px;
  text-decoration: none;
}

.todaysDealsLink:hover{
  background-color: red;
  color: #fff;
  border: 1px solid red;
  transition: 0.3s;

}



.welcomeNavbarLinks_cont {
  /* border: 1px solid blue; */
  width: 70%;
  width: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;

}

.welcomeNavLink_cont{
  /* border: 1px solid red; */
}

.welcomeNavLink {
  text-decoration: none;
  font-size: 16px;
  color: rgb(52, 71, 103);
  margin-right: 30px;
}

.welcomeNavLink:hover {
  color: rgb(14, 113, 195);
}


.welcomeNavbarButtons_cont {
  /* border: 1px solid burlywood; */
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* M O B I L E */

.welcomeNavBrand_contMob{
  display: none;
}

.collapseBtn_contMob{
  display: none;
}

.collapseBtn_contMob{
  display: none;
}
.welcomeSidebar_contMob{
  display: none;
}



@media (min-width: 320px) and (max-width: 480px ) {
  .welcomeNavbarMain_cont{
    height: 10vh;
  }
  .welcomeNavbarBrand_cont, .welcomeLinkAndBtn_cont, .welcomeNavbarLinks_cont, .welcomeNavbarButtons_cont{
    display: none;
  }

  .welcomeNavBrand_contMob{
    /* border: 1px solid blue; */
    display: block;
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .collapseBtn_contMob{
    /* border: 1px solid blue; */
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .collapseBtn_cont{
    border: 2px solid rgb(14, 113, 195);
    height: 30px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
  
  .collapseBtn_contMob{
    /* border: 1px solid green; */
  }

  .welcomeSidebar_contMob {
    position: fixed;
    top: 10vh;
    right: -62vw;
    height: 90vh;
    width: 60vw;
    /* padding: 20px; */
    padding-left: 10px;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    border-radius: 10px;
    border-end-end-radius: 0px;
    border-start-end-radius: 0px;
  }
  
  .sidebar--open {
    right: 0;
  }
  
  .welcomeSidebar_contMob a {
    text-decoration: none;
    font-size: 16px;
  }

  .welcomeNavbarLinks_contMob{
    /* border: 1px solid red; */
    width: 100%;
    height: auto;
  }

  .welcomeNavLink_contMob{
    /* border: 1px solid blue; */
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
  }


  .welcomeSidebarIconCont{
    /* border: 1px solid blue; */
    border-radius: 10px;
    height: 30px;
    width: 30px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
body.no-scroll {
  overflow: hidden;
}


}