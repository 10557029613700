@import '../../../../App.css';


.segmentFormMain{
  /* border: 3px solid blue; */
}
.newSegmentHeader {
  padding: 20px 30px;
  border-radius: 10px;
  display: flex;
  background-color: #fff;
  justify-content: space-between;
}

.createSegmentMain_cont {
  /* border: 2px solid green; */
  height: 440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f8f9fa;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;
}

.createSegmentMain_cont::-webkit-scrollbar{
  display: none;
}

.segmentNameInput_cont {
  width: 100%;
  padding: 0px 20px;
  display: flex;
  /* border: 1px solid olive; */
}

.segmentHeadingInputCont {
  /* border: 1px solid blue; */
  width: 65%;
  display: flex;
}

.segmentNameHeading {
  min-width: 25%;
  width: 30%;
  height: 100%;
  /* margin-right: 20px; */
  display: flex;
  align-items: center;
  /* border: 1px solid blue; */
}

.segmentNameBox {
  /* border: 1px solid purple; */
  display: flex;
  align-items: center;
  width: 65%;
  margin-right: 10px;
}

.andOrMain_cont {
  /* border: 1px solid red; */
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;

}

.button-box{
  /* border: 1px solid red; */
}

.toggle-btn {
  /* border: 1px solid green; */
  padding: 10px;
  cursor: pointer;
  background: transparent;
  width: 50%;
  /* border: 0; */
  outline: none;
  position: relative;
  text-align: center;
}

#ToggleBtn {
  left: 0;
  top: 0;
  /* position: absolute; */
  width: 50%;
  height: 100%;
  background: rgb(23, 193, 232);
  border-radius: 30px;
  transition: .3s;
}

.createSegErrorCont{
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}


.spaceErrorCont{
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
}
.capitalErrorCont{
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
}

.addCriteria_cont {
  /* border: 1px solid brown; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.addCriteriaBtn {
  height: 36px;
  font-size: 12px;
  --color: rgb(14, 113, 195);
  background: none;
  color: var(--color);
  padding: 0.5rem 1rem;
  border-radius: 10px;
  cursor: pointer;
  border-width: 1px;
  border: 1px solid rgb(14, 113, 195);
  color: rgb(14, 113, 195);
  font-size: 13px;
  transition: box-shadow 0.3s ease 0s;
  min-width: 100px;
  width: auto;
  height: 40px;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  /* margin-bottom: 20px; */
}

.segmentDesc_cont{
  margin-top: 20px;
  width: 100%;
    padding: 0px 20px;
    display: flex;
}

.segmentInputsMain_cont {
  /* border: 1px solid blue; */
  margin-top: 20px;
}

.criteria_row{
  /* border: 1px solid blue; */
  height: auto ;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* display: flex; */

}

.removeCriteria_cont{
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.removeCriteriaSvg{
  margin-top: 5px;
  margin-right: 5px;

}

.removeCriteriaSvg:hover{
  color: var(--primary-color);
}

.criteriaRow_cont{
  display: flex;
  height: auto;
  width: 100%;
}

.criteriaRowCont{
  /* border: 1px solid red; */
  border-radius: 10px;
  height: 100%;
  width: 100%;
  padding: 20px;

}

.segmentInput_cont{
  /* border: 1px solid orange; */
}
.filterSelect{
  width: 100%;
}

.createSegmentBtn_cont {
  /* border: 1px solid purple; */
  margin-top: 20px;
  /* height: 20%; */
  width: 100%;
  /* padding: 0px 20px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;


}

.createSegmentBtn {
  --color: #fff;
  background: rgb(23, 193, 232);
  color: var(--color);
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
  cursor: pointer;
  border-width: 0px;
  border-color: transparent;
  border-style: none;
  font-size: 15px;
  transition: box-shadow 0.3s ease 0s;
  min-width: 100px;
  width: auto;
  height: 45px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}



.createSegmentBtn:hover {
  background-color: rgb(20, 166, 199);
  color: rgb(255, 255, 255);
  border: none;
}

.createSegmentBtn:active {
  background-color: #fff;
  color: rgb(23, 193, 232);
  border: 1px solid rgb(14, 113, 195);
}