.todaysDealsMain {
  /* border: 2px solid red; */
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  overflow-y: scroll;
  padding: 20px;
  padding-bottom: 30px;
  scrollbar-width: none;
}

.todaysDealsMain::-webkit-scrollbar {
  display: none;
}

.todaysDeals_cont1{
  border: 1px solid lightgray;
  border-radius: 10px;
  width: 100%;
  height: 130px;
  padding: 10px;
}

.todaysDeals_cont1 p{
  font-weight: 600;
  font-size: 26px;
}

.todaysDealCont1Sub{
  /* border: 1px solid blue; */
  width: 100%;
  height: auto;
  margin-top: 15px;
  display: flex;

}

.dealsInStockCont{
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 40px;

}

.dealsInStockCont p{
  font-size: 15px;
}

.todaysDealsTableIcon_cont{
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  min-width: 110px;
  width: auto;
  justify-content: center;
}

.priceRangeMain_cont{
  /* border: 1px solid blue; */
  height: auto;
  width: 300px;

}

.priceRangeBtn_cont{
  margin-top: 10px;
  
  display: flex;
  justify-content: flex-end;

}