.sales_main{
  /* border: 1px solid red; */
  height: 85vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}

@media (max-width: 480px) {
  .sales_main {
    width: 100%;
  }
}