@import '../../App.css';

.dashboard_main{
  /* border: 1px solid red; */
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-y: scroll;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
  scrollbar-width: none;
}

 .dashboard_main::-webkit-scrollbar {
   display: none;
 }

 .dashboardLoader_cont{
  height: 80vh;
  width: 100%;
 }

.locationMain_cont{
  /* border: 1px solid blue; */
  display: flex;
  justify-content: space-between;
}

.dateRangePickerMain_cont{
  /* border: 1px solid red; */
  width: 35%;
}

.accountHealth_cont{
  /* border: 1px solid blue; */
  margin-top: 20px;
  height: auto;
  width: 100% ;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dataInfo_cont p{
  font-size: 14px;
  color: #697565;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dfirst_row{
  /* border: 2px solid blue; */
  margin-top: 20px;
  height: 15vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.dfirstrowElm{
  /* border: 2px solid olive; */
  border-radius: 10px;
  background-color: #fff;
  padding: 10px;
  height: 90%;
  width: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  
.dfirstrowfourthElm{
  margin-right: 0px;
}

.data_cont{
  /* border: 1px solid blue; */
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dataHeading_cont{
  /* border: 1px solid blue; */
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  opacity: 1;
  text-transform: capitalize;
  text-decoration: none;
  color: var(--accent-color);
}
.firstrowdata{
  /* border: 1px solid blue; */
  font-size: 1.25rem;
  line-height: 1.375;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: var(--secondary-color);
  font-weight: 800;
}

/* ---------------------------------------------- */

.dsecond_row{
  /* border: 2px solid blue; */
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.dcont_heading{
  /* border: 1px solid blue; */
  margin: 0px;
  margin-bottom: 10px;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  opacity: 1;
  vertical-align: unset;
  text-decoration: none;
  color: var(--accent-color);
  display: flex;
  justify-content: space-between;
}

.graph_cont{
  /* border: 1px solid red; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: var(--accent-color);
  height: 87%;
  width: 100%;
  max-width: 100%;
  padding: 10px;
  padding-bottom: 0px;
}
.graphsmall_cont{
  /* border: 1px solid red; */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: var(--accent-color);
    height: 82%;
    /* width: 100%; */
    max-width: 99%;
    padding: 10px;
    padding-bottom: 0px;
}

.dsecondrowElm{
  height: 100%;
  width: 50%;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  margin-right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dsecondFirstElm{
  width: 50%;
  /* width: 40%; */
}
.dsecondSecondElm{
  width: 50%;
  /* width: 60%; */
  margin-right: 0px;
}


/* ---------------------------------------------- */

.dthird_row{
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.dthirdrowElm{
  height: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  margin-right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dthirdrowfirstElm{
  width: 60%;
}
.dthirdrowsecondElm{
  width: 40%;
  margin-right: 0px;
}
.dthirdrowthirdElm{
  width: 40%;
  margin-right: 0px;
}

/* ---------------------------------------------- */

.dfourth_row{
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.dfourthrowElm{
  width: 40%;
  height: 100%;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  margin-right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dfourthrowfirstElm{
  width: 50%;
  width: 40%;
}
.dfourthrowsecondElm{
  width: 50%;
  width: 60%;
  margin-right: 0px;
}

/* ---------------------------------------------- */

.dfifth_row {
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.dfifthrowElm {
  width: 40%;
  height: 100%;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  margin-right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dfifthrowfirstElm {
  width: 50%;
  width: 60%;
}

.dfifthrowsecondElm {
  width: 50%;
  width: 40%;
  margin-right: 0px;
}





@media (max-width: 480px) {
  .dashboard_main {
    width: 100%;
  position: unset;
  }
}