.products_main{
  /* border: 2px solid purple; */
  height: 100%;
  width: 100%;
  padding: 20px;
    padding-top: 10px;
    padding-bottom: 30px;
}

.searchInput_cont {
  /* display: flex; */
  width: 30%;
  /* border: 1px solid blue; */
}

.productTableMain_cont {
  /* border: 2px solid red; */
  height: auto;
  /* padding: 20px; */
  padding-top: 20px;
  display: flex;
  justify-content: center;
}


.productTable_cont {
  /* border: 2px solid blue; */
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 480px) {
  .products_main {
    width: 100%;
  }
}