.connectStep2Main_cont{
  /* border: 2px solid red; */
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  overflow-y: scroll;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
}
.stepSpan{
  display: flex;
  align-items: center;
  /* border: 1px solid blue; */
}

.facebookBtn_cont{
  /* border: 1px solid red; */
  margin-top: 10px;
  height: auto;
  width: 30%;
}

.facebookBtn{
  background-color: #1877F2;
    border: none;
    /* width: 70%; */
    padding: 10px;
    font-size: 14px;
    border-radius: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
}
