@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');


.knowledgeBasedMain_cont{
  /* border: 1px solid orange; */
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  padding-top: 10px;
  padding: 20px;
  padding-bottom: 0px;
}


.mandatoryMain_cont {
  /* border: 1px solid blue; */
  height: 100%;
  width: 100%; 
}

.knowledgeMainHead{
  text-align: center;
}

.knowledgeSubHead{
  font-weight: 700;
  text-align: center;
}

.mandatoryCards_cont {
  /* border: 1px solid red; */
  margin-top: 20px;
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.mandatoryCard {
  /* border: 1px solid green; */
  border-radius: 10px;
  height: 100%;
  width: 30%;
  padding: 20px;
}

.mandatoryCard11 {
  background-color: #f2e9fd;
  /* border: 5px solid #dec1e7; */
  box-shadow: 6px 8px 4px #dec1e7;
}

.mandatoryCard12 {
  background-color: #fdf2d7;
  /* border: 5px solid #f3ec9c; */
  box-shadow: 6px 8px 4px #f3ec9c;
}

.mandatoryCard13 {
  background-color: #e2eefe;
  /* border: 5px solid #87b8ea; */
  box-shadow: 6px 8px 4px #87b8ea;
}

.mandatorySvgMain_cont {
  /* border: 1px solid blue; */
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mandatorySvg_cont {
  /* border: 1px solid green; */
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mandatoryImage {
  height: 90%;
  width: 90%;
}

.mandatoryHead_cont {
  /* border: 1px solid purple; */
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mandatoryContent_cont {
  /* border: 1px solid brown; */
  height: auto;
  padding: 0px 10px;
}

.mandatoryCardHead {
  text-align: center;
}

.mandatoryPara {
  margin-bottom: 15px;
  font-size: 15px;
}

.mandatoryPara a {
  /* text-decoration: none; */
  font-weight: 700;
  color: black;
}



/*  M E S S A G E   L I M I T S  */



.messageLimitsMain_cont {
  /* border: 1px solid blue; */
  height: 100%;
  width: 100%;
  margin-top: 5%;
  /* background-color: #fff; */
}

.limitsCards_cont{
  /* border: 1px solid red; */
    margin-top: 20px;
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.limitsCard{
  /* border: 1px solid green; */
    border-radius: 10px;
    height: 100%;
    width: 17%;
    padding: 10px;
    text-align: center;

}

.limitsCard11{
  background-color: #d4f4e6;
  /* border: 5px solid #78d88b; */
  box-shadow: 6px 8px 4px #78d88b;

}
.limitsCard12{
  background-color: #e3efff;
  /* border: 5px solid #74b3f5; */
  box-shadow: 6px 8px 4px #74b3f5;

}
.limitsCard13{
  background-color: #f2e8fd;
  /* border: 5px solid #e4aff8; */
  box-shadow: 6px 8px 4px #e4aff8;

}
.limitsCard14{
  background-color: #fdf2d7;
  /* border: 5px solid #f9e388; */
  box-shadow: 6px 8px 4px #f9e388;

}
.limitsCard15{
  background-color: #d4f4e6;
  /* border: 5px solid #77d98b; */
  box-shadow: 6px 8px 4px #77d98b;

}

.limitsCardHead_cont{
  /* border: 1px solid blue; */
  text-align: center;
  border-radius: 10px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.limitsCardHead_cont1{
  background-color: #78d88b;
}
.limitsCardHead_cont2{
  background-color: #74b3f5;
}
.limitsCardHead_cont3{
  background-color: #e4aff8;
}
.limitsCardHead_cont4{
  background-color: #f9e388;
}
.limitsCardHead_cont5{
  background-color: #77d98b;
}

.limitsCardHead{

}

.limitsCardContent_cont{
  /* border: 1px solid green; */
  margin-top: 20px;
}

.limitsPara{
  font-size: 15px;
}

.limitsData{
  font-size: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.guidelineLinksCont{
  /* border:2px solid #FD5D5D; */
  border-radius: 10px;
  background-color: #FFE4C0;
  width: 75%;
  height: 60px;
  margin: auto;
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  box-shadow: 4px 4px 4px #FD5D5D;
}



/* C O N V E R S A T I O N    T Y P E S */


.typeConvMain_cont{
  /* border: 1px solid blue; */
  height: 100%;
  width: 100%;
  margin-top: 5%;
}


.typeConvCards_cont{
  /* border: 1px solid red; */
  height: 85%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.typeConvCard{
  /* border: 1px solid green; */
  border-radius: 10px;
  height: 42%;
  width: 65%;
}

.typeConvCard1{
  background-color: #d4f4e6;
  border: 3px solid #78d88b;
  
}
.typeConvCard2{
  background-color: #e3efff;
  border: 3px solid #74b3f5;

}

.typeConvCardHead_cont{
  /* border: 1px solid blue; */
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.typeConvCardHead_cont1{
  background-color: #78d88b;
}

.typeConvCardHead_cont2{
  background-color: #74b3f5;
}

.typeConvContentMain_cont{
  /* border: 1px solid blue; */
  height: 72%;
  display: flex;
}
.typeSvg_cont{
  /* border: 1px solid green; */
  height: 100%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.typeSvg_cont img{
  height: 70%;
  width: 70%;

}
.typeConvContent_cont{
  /* border: 1px solid green; */
  height: 100%;
  width: 70%;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.typeConvPara{
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}



/* C H A R G I N G    M O D E L */



.chargingModelMain_cont{
  /* border: 1px solid blue; */
  height: 100%;
  width: 100%;
  margin-top: 5%;
}

.chargingModelCards_cont{
  /* border: 1px solid red; */
    height: 40%;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.chargingModelCard{
  border: 3px solid #8967B3;
  border-radius: 10px;
  background-color: #F1EAFF;
  height: 100%;
  width: 26%;
  padding: 15px;

}

.chargingModelCardHead{
  font-weight: 700;
  font-size: 17px;

}

.chargingModelCardPara{
  margin-top: 20px;
  font-size: 15px;
}

.chargingArrow_cont{
  /* border: 1px solid blue; */
}

.chargingArrow_cont img{
height: 30px;
width: 30px;
}


.chargingModelSec_cont{
  /* border: 1px solid red; */
  height: 30%;
  width: 60%;
  margin: auto;
  margin-top: 20px;
  display: flex;
}

.comCont1{
  /* border: 1px solid green; */
  height: 100%;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comCont1 img{
  height: 80%;
  width: 80%;
}
.comCont2{
  /* border: 1px solid green; */
  height: 100%;
  width: 60%;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.comCont2 img{
  height: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.comCont3{
  /* border: 1px solid green; */
  height: 100%;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comCont3 img {
  height: 80%;
  width: 80%;
}


/* W H A T S   A P P   C O S T S */


.whatsappCostsMain_cont{
  /* border: 1px solid blue; */
  height: 100%;
  width: 100%;
  margin-top: 5%;
}

.whatsappCostsCards_cont{
  /* border: 1px solid red; */
  height: 80%;
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.whatsappCostsCard{
  border-radius: 10px;
  height: 100%;
  width: 38%;
  padding: 20px;
}

.whatsappCostsCard1{
  background-color: #d4f4e6;
  box-shadow: 6px 8px 4px #78d88b;
}

.whatsappCostsCard2{
  background-color: #e3efff;
  box-shadow: 6px 8px 4px #74b3f5;
}


.costCardImg_cont{
  /* border: 1px solid blue; */
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.costCardImg_cont img{
  height: 80%;
}

.costCardHead_cont{
  /* border: 1px solid red; */
  height: auto;
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.costCardContent_cont{
  /* border: 1px solid green; */
  height: auto;
}

.whatsappCostsPara{
  font-size: 16px;
  margin-bottom: 5px;
}