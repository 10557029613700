.paySuccessMain_cont{
  /* border: 1px solid blue; */
  height: 100%;
  width: 100%;
  padding-top: 50px;
  display: flex;


  justify-content: center;
}

.paySuccess_cont {
  /* border: 1px solid red; */
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  height: 70%;
  width: 50%;
  margin: 0px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.paySuccessPara {
  text-align: center;
  margin-top: 20px;
  font-size: 24px;
  font-weight: 800 !important;
  color: #333;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.paySuccess_cont p{
  text-align: center;
  margin-top: 20px;
}

.paySuccessImg_cont {
  /* border: 1px solid green; */
  height: 40%;
  width: 100%;
}

.paySuccessImg_cont img {
  height: 100%;
  /* width: 100%; */
}

.paySuccessBtn {
  width: 100%;
  margin-top: 20px;
  font-size: 16px;
}