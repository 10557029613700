.welFooterMain_cont{
  /* border: 1px solid blue; */
  height: auto;
  width: 100%;
  padding: 40px ;
  background-color: #040B18;
  /* background-color: #10375C; */
  color: #fff;
}

.welFooterMain_cont hr{
  border-bottom: 0px;
}

.welFooterSub_cont1{
  /* border: 1px solid green; */
  height: auto;
  display: flex;
  justify-content: space-evenly;
}

.welFooterLinks_cont{
  /* border: 1px solid red; */
  height: 300px;
  width: 230px;
  height: auto;
}

.footerBrandCont{
  /* border: 1px solid purple; */
  height: 25%;
  width: 100%;
}

.footerBrandCont img{
  height: 100%;
  width: 100%;
}

.welFootSocialLinks_cont{
  /* border: 1px solid green; */
  margin-top: 20px;
  display: flex;
}

.footSocialLink_cont{
  /* border: 1px solid red; */
  height: 50px;
  width: 100%;
}

.welFootCardHead{
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #fff !important ;
}

.welFooterLinks_cont p{
  margin-bottom: 15px;
  font-size: 16px;
  color: darkgray;
}

.welFooterSub_cont2{
  /* border: 1px solid green; */
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 30px auto;
  margin-bottom: 0px;
}

.welFootLink{
  color: #fff;
  text-decoration: none;
}

.welFootLink:hover{
  /* text-decoration: underline; */
}