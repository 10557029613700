.connectStep1Main_cont{
  /* border: 2px solid red; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    overflow-y: scroll;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 30px;
    scrollbar-width: none;

}

.connectStep1Main_cont::-webkit-scrollbar{
  display: none;
}

.stepSpan{
  font-size: 12px;
}
.connectStepSub_Cont{
  height: auto;
  width: 100%;
  padding: 40px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.connectStep1Cont{
  /* border: 1px solid blue; */
  width: 70%;
  margin-bottom: 30px;
}

.connectStep1Inputs_cont{
  /* border: 1px solid red; */
  width: 55%;
  display: flex;

}

.dialSelectStep1{
  /* border: 1px solid green; */
  margin-right: 4px;
  width: 100px;
}
.connectStep1Input{
  /* border: 1px solid green; */
  width: 100%;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.connectStep1CategoryBox{
  width: 100%;
}