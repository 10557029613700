.forgotMain_cont{
  height: 100vh;
  width: 100vw;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;
}

.passChangeSuccess {
  /* border: 1px solid blue; */
  border-radius: 10px;
  background-color: #fff;
  /* height: 70%; */
  height: auto;
  width: 30%;
  padding: 20px;
  margin: 0px auto;
  margin-top: 100px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.otpSuccessPara{
  color: gray;
  font-size: 12px;
  text-align: center;
}

.passChangedHead{
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); */
  text-shadow: 1px 1px 2px rgba(0, 255, 0, 0.7);
}