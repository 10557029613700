@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
@import '../../../App.css';

.searchProductMain_cont {
  /* border: 1px solid blue; */
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-y: scroll;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
  scrollbar-width: none;
}

.searchProductCont1 {
  /* border: 1px solid red; */
  border-radius: 10px;
  margin: auto;
  margin-top: 20px;
  padding: 20px;
  height: 200px;
  width: 70%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.searchProductForm_cont {
  /* border: 1px solid blue; */
  height: 100%;
  width: 100%;
}

.searchProductFrom_Row{
  /* border: 1px solid blue; */
  width: 100%;
  display: flex;
  height: auto;
}

.productFilter_cont{
  /* border: 1px solid green; */
  width: 25%;
  height: 100%;
  margin-right: 20px;
}

.keywords_cont{
  /* border: 1px solid green; */
  width: 75%;
  height: 100%;
}


.searchProductLabel {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}


.productSearchBtn_cont {
  /* border: 1px solid green; */
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.searchProductCont2{
  /* border: 1px solid red; */
  border-radius: 10px;
  background-color: #fff;
  margin-top: 30px;
  height: 52%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.productInfoCards_cont{
  /* border: 1px solid blue; */
  padding: 15px;
  height: 80%;
  display: flex;
  justify-content: space-around;
}

.searchBrand_cont{
  border: 1px solid lightblue;
  border-radius: 10px;
  height: 100%;
  width: 31%;
  padding: 10px 20px;
}

.searchBrand_cont p{
  margin-bottom: 5px;
}


.searchCategory_cont{
  border: 1px solid lightblue;
  border-radius: 10px;
  height: 100%;
  width: 31%;
  padding: 10px 20px;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;
}

.searchCategory_cont p{
  margin-bottom: 5px;
}

.searchContactInfo_cont{
  border: 1px solid lightblue;
  border-radius: 10px;
  height: 100%;
  width: 31%;
  padding: 10px 20px;
}

.searchContactInfo_cont p{
  margin-bottom: 5px;
}
.searchContactInfo_cont p span{
  font-weight: 700;
}

.searchProductCardHead{
  margin-bottom: 10px;
  color: var(--secondary-color);
}

.viewMoreBtn_cont{
  /* border: 1px solid red; */
  padding-right: 50px;
  display: flex;
  justify-content: flex-end;
}

.viewMoreBtn{
  height: 40px;
}

.searchProductCont3{
  /* border: 1px solid red; */
  border-radius: 10px;
  background-color: #fff;
  margin-top: 30px;
  height: auto;
  padding: 30px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}