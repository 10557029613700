.newKeySubCont{
  height: auto;
  padding: 0px 20px;
}


.keywordSaveBtn_cont{
  /* border: 1px solid blue; */
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}