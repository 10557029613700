@import "../../App.css";

.consumer_main{
  /* border: 2px solid green; */
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-y: scroll;
  padding: 20px;
  padding-bottom: 30px;
  scrollbar-width: none;
}

.consumer_main::-webkit-scrollbar{
  display: none;
}


.uploadContactBtn_cont{
  /* border: 1px solid blueviolet; */
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.importContactsBtn{
  margin-right: 15px;
}

.conMainChartMain_cont{
  /* border: 1px solid red; */
  width: 100%;
  margin-top: 20px;
  height: 70vh;
}

.conMainChart_cont{
  /* border: 2px solid green; */
  border-radius: 10px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}
.conMainChart{
  /* border: 2px solid red; */
  height: 70%;
}
.conLegendMain_cont{
  border: 1px solid violet;
  height: 10%;
}

.contactTableMain_cont {
  /* border: 2px solid red; */
  height: auto;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.contactTable_cont {
  /* border: 2px solid blue; */
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 480px) {
  .consumer_main {
    width: 100%;
  }
}