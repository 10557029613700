.afterBroadcastMain_cont{
  /* border: 2px solid red; */
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  overflow-y: scroll;
  padding: 20px;
  /* padding-top: 10px; */
  scrollbar-width: none;
}

.afterBroadcastMain_cont::-webkit-scrollbar{
  display: none;
}

.afterBroadcastSub_cont{
  /* border: 1px solid blue; */
  height: auto;
  width: 100%;
  border-radius: 10px;
}

.overviewBroadMain_cont{
  /* border: 1px solid blue; */
  border-radius: 10px;
  margin-bottom: 30px;
  margin-top: 10px;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overviewConts{
  /* border: 1px solid red; */
  height: 100%;
  width: 100%;
  padding: 15px;
  margin-right: 30px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.overviewData_cont{
  /* border: 1px solid blue; */
  display: flex;
  height: 65%;
  width: 100%;
}
.overviewData{
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
}
.overviewSvgCont{
  /* border: 1px solid orange; */
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/* .overviewSvg{
  border-radius: 50%;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */
.overviewDesc_cont{
  /* border: 1px solid red; */
  height: 35%;
  width: 100%;
  display: flex;
  align-items: center;
  /* padding: 10px; */
}





.sentMessageTable_cont{
  /* border: 2px solid blue; */
  height: auto;
  margin-top: 10px;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    /* overflow: auto;
      overflow-y: scroll;
      scrollbar-width: none; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.sentSegName{
  color: blue;
  cursor: pointer;
  text-decoration: none;
}