.signupMain_cont {
  /* border: 1px solid blue; */
  height: 100vh;
  width: 100vw;
  overflow: auto;
    overflow-y: scroll;
    scrollbar-width: none;

}

.signupCardMain_cont {
  /* border: 1px solid blue; */
  border-radius: 10px;
  background-color: #fff;
  /* height: 80%; */
  width: 45%;
  padding: 20px;
  margin: 0px auto;
  margin-top: 100px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.loginBrand_cont {
  /* border: 1px solid blue; */
  height: 30px;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
}

.loginBrand_cont img {
  height: 100%;
  width: 30%;
}

.signupWelcomeHead_cont {
  /* border: 1px solid red; */
  /* text-align: center; */
  margin-bottom: 10px;
}

.signupWelcomeHead_cont p {
  color: gray;
  font-size: 12px;
}

.signupFormInput_cont {
  /* border: 1px solid blue; */
  width: 100%;
  height: 100%;
}

.newUserContact_cont{
  /* border: 1px solid blue; */
}

.signupRow1 {
  /* border: 1px solid red; */
  display: flex;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.signupRow2 {
  /* border: 1px solid red; */
  display: flex;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.signupRow3 {
  /* border: 1px solid red; */
  display: flex;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.signupBtn_cont {
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
}


/* HTML: <div class="loader"></div> */
.loader {
  margin-left: 10px;
  width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 6px solid rgb(23, 193, 232);
  animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;
}

@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%)
  }

  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%)
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%)
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%)
  }

  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%)
  }

  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%)
  }

  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%)
  }
}

@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg)
  }

  49.99% {
    transform: scaleY(1) rotate(135deg)
  }

  50% {
    transform: scaleY(-1) rotate(0deg)
  }

  100% {
    transform: scaleY(-1) rotate(-135deg)
  }
}


.loaderWhite {
  margin-left: 10px;
  width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 6px solid #fff;
  animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;
}

.dashboardLoader{
  margin-left: 10px;
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 6px solid rgb(23, 193, 232);
  animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;
}
