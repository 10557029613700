.viewMarketMain_cont{
  /* border: 1px solid blue; */
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-y: scroll;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
  scrollbar-width: none;
}

.marketplaceTableMain_cont{
  /* border: 1px solid red; */
    height: auto;
    /* padding-top: 30px; */
    display: flex;
    justify-content: center;
}

.marketNickname_cont{
  /* border: 1px solid blue; */
  width: 260px;
  display: flex;
  /* justify-content: space-between; */
}
.marketNickname{
  margin-right: 10px;
}