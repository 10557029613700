.viewRecipients_cont{
  /* border: 2px solid green; */
    height: 390px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.viewRecipientsTable_cont{
  /* border: 2px solid blue; */
  height: 100%;
  /* margin-top: 10px; */
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}