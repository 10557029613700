@import '../../App.css';

.usersMain_cont {
  /* border: 1px solid red; */
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-y: scroll;
  padding: 20px;
  padding-bottom: 30px;
  scrollbar-width: none;
}

.usersToolMain_cont {
  /* border: 1px solid blue; */
  border-radius: 10px;
  background-color: #EEEEEE;
  margin-bottom: 30px;
  height: 20%;
  padding: 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.usersTool_cont1 {
  /* border: 1px solid red; */
  border-radius: 10px;
  background-color: #fff;
  height: 60%;
  width: 20%;
  padding: 10px;
  display: flex;
}

.usersTab {
  /* border: 1px solid green; */
  border-radius: 10px;
  color: black;
  width: 50%;
  height: 100%;
  margin-right: 10px;
  text-decoration: none;
  cursor: pointer;
  transition: 0.4s;
}

.rolesTab {
  /* border: 1px solid green; */
  border-radius: 10px;
  color: black;
  width: 50%;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
  transition: 0.4s;
}


.userTabActive {
  background-color: var(--primary-color);
  color: #fff;
}

.usersTool_cont2 {
  /* border: 1px solid red; */
  border-radius: 10px;
  background-color: #fff;
  height: 60%;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.userSearch_cont {
  /* border: 1px solid blue; */
  width: 300px;
  margin-right: 20px;
}

.searchUserInput {
  width: 100%;
}

.addUserBtn_cont {
  width: auto;
}

.addUserBtn {
  border-radius: 10px;
}

/* ---------------------------------------- */

/* U S E R S   T A B L E */

.usersTable_cont {
  width: 100%;
  border-collapse: collapse;
}

.userHeadRow {
  /* border: 1px solid purple; */
  border-radius: 10px;
  background-color: #e2eefe;
  display: flex;
  height: 60px;
  font-weight: 700;
}

.userRow {
  margin: 5px 0;
}

.userRowInner {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  height: 60px;
  background-color: #e2eefe;
}

.userTableHeadCell {
  /* border: 1px solid green; */
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userTableCell {
  /* border: 1px solid blue; */
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.userNameCont {
  min-width: 14%;
}

.userStatusCont {
  min-width: 8%;
}

.userEmailCont {
  min-width: 24%;
}

.userRoleCont {
  min-width: 14%;
  text-align: center;
}

.userDateCont {
  min-width: 16%;
}

.userLastLoginCont {
  min-width: 16%;
}

.userActionCont {
  min-width: 8%;
}

.userStatus {
  border-radius: 10px;
  padding: 8px 18px;
  width: 90px;
}

.inactiveBtn {
  border: none;
  background-color: rgb(255, 231, 231);
  padding: 5px;
  font-weight: 700;
  border-radius: 5px;
  color: rgb(255, 14, 14);
  min-width: 70px;
  cursor: pointer;
}

.inactiveBtn:hover {
  border: 1px solid rgb(255, 14, 14);
}

.activeBtn {
  border: none;
  background-color: rgb(233, 246, 238);
  padding: 5px;
  font-weight: 700;
  border-radius: 5px;
  color: rgb(35, 164, 85);
  min-width: 70px;
}

.activeBtn:hover {
  border: 1px solid rgb(35, 164, 85);
}

.userDeleteBtn {
  border: none;
  background-color: rgb(255, 231, 231);
  height: 40px;
  width: 40px;
  padding: 5px;
  font-weight: 700;
  border-radius: 50%;
  color: rgb(255, 14, 14);
  cursor: pointer;
  margin-left: 10px;
}

.userDeleteBtn:hover {
  border: 1px solid rgb(255, 14, 14);
}


.userEditBtn {
  border: none;
  background-color: rgb(233, 246, 238);
  height: 40px;
  width: 40px;
  padding: 5px;
  font-weight: 700;
  border-radius: 50%;
  color: rgb(35, 164, 85);
  cursor: pointer;
}

.userEditBtn:hover {
  border: 1px solid rgb(35, 164, 85);
}

/* R O L E S   T A B L E */

.rolesTable_cont {
  width: 100%;
  border-collapse: collapse;
}

.rolesRow {
  margin: 5px 0;
}

.rolesRowInner {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  height: 60px;
  background-color: #e2eefe;
}

.roleNameCont {
  min-width: 20%;
}

.roleDescCont {
  min-width: 40%;
}

.roleDateCont {
  min-width: 20%;
}

.roleActionsCont {
  width: 10%;
}

.noUsers_cont {
  /* border: 1px solid blue; */
  height: 60vh;
  text-align: center;
  margin: auto;
}

.usersLoader_cont {
  height: 60vh;
  width: 100%;
}