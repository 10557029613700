.welcomeMain_cont {
  /* border: 1px solid purple; */
  height: 100vh;
  width: 100%;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.welcomeMain_cont::-webkit-scrollbar{
  display: none;
}