/* welcome hero starts */
.welcomeHeroMain_cont {
  /* border: 1px solid red; */
  width: 100%;
  height: 87vh;
  display: flex;
}

.hero_cont1 {
  /* border: 1px solid blue; */
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.heroSub_cont1 {
  /* border: 1px solid green; */
  width: 90%;
  height: 70%;
  color: rgb(52, 71, 103);
}

/* Define the keyframes for the slide-in animation */
@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Apply the animation to the heroSub_cont1 class */
.heroSub_cont1 {
  /* Initial state before animation */
  transform: translateX(-100%);
  opacity: 0;
  animation: slideInFromLeft 1s ease-out forwards;
}


.heroBigHead {
  font-size: 42px;
}

.herosubHead {
  font-size: 24px;
}

.heroSmallHead {
}

.heroBtn_cont {
}

.heroMarket_cont {
  display: flex;
  width: 100%;
}

.heroMarket {
  margin-right: 100px;
}


.hero_cont2 {
  /* border: 1px solid blue; */
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  animation: float 3s ease-in-out infinite;
}

.heroSub_cont2 {
  /* border: 1px solid green; */
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* animation: float 3s ease-in-out infinite; */
  transform: translateY(30%);
  opacity: 0;
  transition: opacity 1s ease-out, transform 1s ease-out;
}

/* Define the keyframes for the slide-in animation from the bottom */
@keyframes slideInFromBottom {
  from {
    transform: translateY(30%);
    /* Start off-screen below */
    opacity: 0;
  }

  to {
    transform: translateY(0);
    /* End at its final position */
    opacity: 1;
  }
}

.heroSub_cont2.animate {
  animation: slideInFromBottom 1s ease-out forwards;
}

.heroImg {
  height: 100%;
  width: 100%;
}

/* Define the keyframes for the floating animation */
@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-25px);
    /* Adjust the value for more or less movement */
  }

  100% {
    transform: translateY(0);
  }
}

/* ---------------------------------------- */
/* welcome connect starts */


.reveal {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.sectionHead_cont {
  height: auto;
  /* border: 1px solid purple; */
  color: #27374D;
  font-size: 24px;
  text-transform: uppercase;
}

.sectionHeading {
  text-align: center;
}

.welcomeConnectMain_cont {
  /* border: 1px solid blue; */
  height: 85vh;
  /* display: flex; */
}

.welcomeConnectSub_cont {
  /* border: 1px solid orange; */
  height: 85%;
  display: flex;
}

.connect_cont1 {
  /* border: 1px solid green; */
  color: rgb(52, 71, 103);
  height: 100%;
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  padding-bottom: 0px;
}

.connectSub_cont1 {
  /* border: 1px solid red; */
  height: 100%;
  width: 100%;
}

.connectBigHead {
  font-size: 26px;
  font-weight: 600;
}

.connectPara {
  margin-bottom: 15px;
}

.connect_cont2 {
  /* border: 1px solid green; */
  height: 100%;
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  padding-bottom: 0px;
  padding-left: 0px;
}

.connectSub_cont2 {
  /* border: 1px solid red; */
  height: 100%;
  width: 100%;
}


.welcomeConnectSubHead_cont {
  /* border: 1px solid purple; */
  color: rgb(52, 71, 103);
  width: 100%;
}

.connect_cont3 {
  display: none;
}

/* ---------------------------------------- */
/* Welcome marketplace starts */
.welcomeMarketplaceMain_cont {
  /* border: 1px solid red; */
  height: 120vh;
}

.marketplace_cont {
  /* border: 1px solid green; */
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  height: 150px;
}

.marketplace {
  /* border: 1px solid blue; */
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.marketplaceSub_cont {
  /* border: 3px solid blue; */
  height: 65%;
  display: flex;
}

.marketplace_cont1 {
  /* border: 1px solid orange; */
  padding: 30px;
  height: 100%;
  width: 50%;
}

.marketplaceSub_cont1 {
  /* border: 1px solid purple; */
  height: 100%;
  color: rgb(52, 71, 103);
}

.marketplace_cont2 {
  /* border: 1px solid orange; */
  padding: 30px;
  height: 100%;
  width: 50%;
}

.marketplaceSub_cont2 {
  /* border: 1px solid purple; */
  height: 100%;
  color: rgb(52, 71, 103);
}



/* ---------------------------------------- */
/* Welcome wholesale starts */
.welcomeWholesaleMain_cont {
  /* border: 2px solid green; */
  height: 85vh;
}

.welcomeWholesaleSub_cont {
  /* border: 1px solid blue; */
  display: flex;
  height: 85%;
}

.wholesale_cont1 {
  /* border: 1px solid red; */
  height: 100%;
  width: 50%;
  padding: 30px;
}

.wholesaleSub_cont1 {
  /* border: 1px solid green; */
  height: 100%;
  width: 100%;
  color: rgb(52, 71, 103);
}

.wholesale_cont2 {
  /* border: 1px solid red; */
  height: 100%;
  width: 50%;
  padding: 30px;

}

.wholesaleSub_cont2 {
  /* border: 1px solid green; */
  height: 100%;
  width: 100%;
  color: rgb(52, 71, 103);
}



/* ---------------------------------------- */
/* Welcome Consmers */
.welcomeConsumersMain_cont {
  /* border: 1px solid purple; */
  height: 100vh;
}

.welcomeConsumersSub_cont {
  /* border: 1px solid blueviolet; */
  height: 85%;
  width: 100%;
  display: flex;
}

.consumer_cont1 {
  /* border: 1px solid black; */
  height: 100%;
  width: 65%;
  padding: 30px;
}

.consumerSub_cont1 {
  /* border: 1px solid red; */
  height: 100%;
  width: 100%;
  color: rgb(52, 71, 103);
}

.consumer_cont2 {
  /* border: 1px solid black; */
  height: 100%;
  width: 35%;
  padding: 30px;
  padding-left: 0px;
}

.consumerSub_cont2 {
  /* border: 1px solid red; */
  height: 100%;
  width: 100%;
  color: rgb(52, 71, 103);
  display: flex;
  justify-content: center;
  align-items: center;
}

.consumer_chart {
  /* border: 1px solid blue; */
  height: 60%;
  width: 100%;
}

.consumer_cont3 {
  display: none;
}

/* ------------------------------------------------- */
/* Welcome pricing starts */
.welcomePricingMain_cont {
  /* border: 1px solid blue; */
  min-height: 110vh;
  height: auto;
  width: 100%;
}

.welcomePriceingSub_cont {
  /* border: 1px solid palevioletred; */
  height: 80%;
  width: 80%;
  margin: auto;
  padding: 30px;
}


.pricingCardMain_cont {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.pricingCard_cont {
  /* border: 1px solid purple; */
  border-radius: 10px;
  padding-bottom: 15px;
  height: 100%;
  width: 30%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pricingCard_cont:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.pricingCardName_cont {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(23, 193, 232);
  color: #fff;
  border-radius: 10px;
}

.priceCardName {
  font-size: 22px;
  font-weight: 500;
  color: #27374D;
  color: #fff;
}

.pricingCardSub_cont {
  /* border: 1px solid blue; */
  padding: 20px 30px;
  color: rgb(52, 71, 103);
}


.price {
  font-size: 30px;
  /* color: rgb(23, 193, 232); */
  margin-bottom: 30px;
  text-align: center;
}

.perMonthSpan {
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: #27374D;
  text-align: center;
  color: rgb(14, 113, 195);
}

.pricingFeatureList {
  list-style-type: none;
  text-align: center;

}

.pricingFeatureList li {
  /* border: 1px solid brown; */
  font-size: 15px;
  line-height: 1.8;
  display: flex;
  align-items: center;
}



.priceBtn_cont {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
}


.priceBtn {
  width: 90%;
}


/* ---------------------------------- */
/* Contact starts */
.welcomeContactMain_cont {
  /* border: 1px solid red; */
  height: 125vh;
}


.welcomeContactSub_cont {
  /* border: 1px solid blue; */
  height: 90%;
  width: 100%;
  display: flex;

}

.contact_cont1 {
  /* border: 1px solid green; */
  height: 100%;
  width: 50%;
  padding: 30px;
}

.contactSub_cont1 {
  /* border: 1px solid purple; */
  border-radius: 10px;
  height: 100%;
  width: 100%;
  background-color: #fff;
  color: rgb(52, 71, 103);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.contactLinksMain_cont {
  /* border: 1px solid red; */
  height: 50%;
  width: 100%;
}

.contactLinks_cont {
  /* border: 1px solid blue; */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5px;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.contactLinkSvg_cont {
  /* border: 1px solid red; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
}

.contactLink_cont {
  /* border: 1px solid purple; */
  padding-left: 20px;
}

.contactLinkHeading {
  font-size: 20px;
  font-weight: 500;
}



.contactMap_cont {
  /* border: 1px solid blue; */
  border-radius: 10px;
  height: 50%;
  position: relative;
}

.contactMap_cont iframe {
  border: 0;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.contact_cont2 {
  /* border: 1px solid green; */
  height: 100%;
  width: 50%;
  padding: 30px;
}

.contactSub_cont2 {
  /* border: 1px solid purple; */
  border-radius: 10px;
  height: 100%;
  width: 100%;
  background-color: #fff;
  color: rgb(52, 71, 103);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.formInput_cont {
  /* border: 1px solid green; */
  margin-bottom: 15px;
}

.welcomeFormLabel {
  margin-bottom: 5px;
  display: block;
}

.welcomeFormInput {
  width: 100%;
}






@media (min-width: 320px) and (max-width: 480px) {
  p {
    font-size: 14px;
  }

  .welcomeHeroMain_cont {
    /* border: 1px solid blue; */
    margin-bottom: 60px;
    display: block;
    height: auto;

  }

  .hero_cont1 {
    /* border: 1px solid blue; */
    width: 100%;
    height: auto;
    /* height: 90vh; */
    padding: 15px;
  }

  .heroSub_cont1 {
    /* border: 1px solid red; */
    width: 100%;
    height: auto;
  }

  .heroBigHead {
    font-size: 28px;
  }

  .herosubHead {
    font-size: 18px;
    font-weight: 550;
  }

  .heroSmallHead {
    font-size: 14px;
    font-weight: 450;
  }

  .heroBtn_cont {
  }

  .hero_cont2 {
    display: none;
  }

  /* CLIENTS */
  .welcomeClientsMain_cont {
    /* border: 1px solid blue; */
    margin-bottom: 60px;
    width: 100%;
    height: auto;
    height: 70vh;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .client_cont1 {
    width: 100%;
    height: 45%;
  }

  .client_cont1 img {
    height: 90%;
    width: 90%;
    padding: 10px;
  }

  .client_cont2 {
    width: 100%;
    height: 45%;
  }

  .client_cont2 img {
    height: 90%;
    width: 90%;
    padding: 10px;
  }

  /* CONNECT */

  .sectionHead_cont {
    font-size: 18px;
  }

  .welcomeConnectMain_cont {
    /* border: 1px solid blue; */
    margin-bottom: 60px;
    height: auto;
  }

  .welcomeConnectSub_cont {
    /* border: 3px solid orange; */
    flex-direction: column;
  }

  .connect_cont1 {
    /* border: 1px solid green; */
    width: 100%;
    height: auto;
    padding: 15px;
    display: block;
  }

  .connectSub_cont1 {
    /* border: 1px solid red; */
  }

  .connectBigHead {
    font-size: 18px;
    font-weight: 550;
  }

  .connectPara {}

  .connectSubHead {
    font-size: 14px;
    font-weight: 400;
  }

  .connect_cont2 {
    display: none;
  }

  .connect_cont3 {
    /* border: 1px solid green; */
    width: 100%;
    /* height: 100px; */
    padding: 15px;
    display: block;
  }

  .connectSub_cont2 {
    /* border: 1px solid red; */
  }

  /* MARKETPLACE */
  .welcomeMarketplaceMain_cont {
    margin-bottom: 60px;
    height: auto;
    /* border: 1px solid blue; */
  }

  .marketplace_cont {
    /* border: 1px solid green; */
    width: 90%;
    height: auto;
    border-radius: 10px;
    flex-direction: column;
  }

  .marketplace {
    width: 100%;
  }

  .marketplace img {
    height: 100%;
    width: 60%;
  }

  .marketplaceSub_cont {
    /* border: 1px solid blue; */
    display: block;
    padding: 15px;
  }

  .marketplace_cont1 {
    /* border: 1px solid red; */
    width: 100%;
    padding: 0px;
  }

  .marketplace_cont2 {
    /* border: 1px solid red; */
    width: 100%;
    padding: 0px;
  }

  .marketplaceSubHead {
    font-size: 14px;
    font-weight: 400;
  }


  /* WHOLESALE */

  .welcomeWholesaleMain_cont {
    margin-bottom: 60px;
    /* border: 1px solid blue; */
    height: auto;
  }

  .welcomeWholesaleSub_cont {
    /* border: 1px solid blue; */
    flex-direction: column;
    height: auto;
    padding: 15px;
  }

  .wholesale_cont1 {
    /* border: 1px solid red; */
    width: 100%;
    padding: 0px;
  }

  .wholesale_cont2 {
    /* border: 1px solid red; */
    width: 100%;
    padding: 0px;
  }

  .wholesaleSubHead {
    font-size: 14px;
    font-weight: 400;

  }


  /* CONSUMERS */
  .welcomeConsumersMain_cont {
    /* border: 1px solid blue; */
    margin-bottom: 60px;
    height: auto;

  }

  .welcomeConsumersSub_cont {
    /* border: 1px solid blue; */
    height: auto;
    width: 100%;
    padding: 15px;
    display: block;
    padding: 15px;
  }

  .consumer_cont1 {
    /* border: 1px solid green; */
    width: 100%;
    height: auto;
    padding: 0px;
  }

  .consumerPara {
    font-size: 14px;
  }

  .consumer_cont2 {
    display: none;
  }

  .consumer_cont3 {
    /* border: 1px solid black; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    padding-bottom: 20px;

  }

  .consumerSub_cont2 {
    /* border: 1px solid red; */
    height: auto;
    width: auto;
  }

  .consumer_chart {
    /* border: 1px solid purple; */
    height: 100%;
    width: 100%;
  }


  /* PRICING */

  .welcomePricingMain_cont {
    /* border: 1px solid blue; */
    margin-bottom: 60px;
    height: auto;

  }

  .welcomePriceingSub_cont {
    /* border: 1px solid red; */
    height: auto;
    width: 100%;
    padding: 15px;
  }

  .pricingCardMain_cont {
    /* border: 1px solid green; */
    flex-direction: column;
  }

  .pricingCard_cont {
    width: 100%;
    margin-bottom: 30px;
    border-radius: 10px;
  }

  .pricingCardName_cont {
    height: 50px;
    border-radius: 10px;
  }

  .priceCardName {
    font-size: 18px;
  }

  .pricingCardSub_cont {
    padding: 15px 25px;
  }


  /* CONTACT */

  .welcomeContactMain_cont {
    /* border: 1px solid blue; */
    margin-bottom: 60px;
    height: auto;
  }

  .welcomeContactSub_cont {
    flex-direction: column;
    height: auto;
    width: 100%;
    padding: 15px;
    /* border: 1px solid red; */
  }

  .contact_cont1 {
    /* border: 1px solid green; */
    width: 100%;
    padding: 0px;
  }

  .contactSub_cont1 {
    border-radius: 10px;
    padding: 10px;
  }

  .contactLinksMain_cont {
    /* border: 1px solid red; */
    height: auto;
    width: 100%;
  }

  .contactLinks_cont {
    /* border: 1px solid green; */
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .contactLinkSvg_cont {
    /* border: 1px solid red; */
    height: 40px;
    width: 40px;
  }

  .contactLink_cont {
    padding-left: 10px;
  }

  .contactLinkHeading {
    font-size: 16px;
  }

  .welcomeContactLink {
    font-size: 12px;
    color: rgb(14, 113, 195)
  }

  .contactMap_cont {
    /* border: 1px solid blue; */
    height: 250px;
    border-radius: 10px;
  }

  .contactMap_cont iframe {
    border-radius: 10px;
  }

  .contact_cont2 {
    /* border: 1px solid green; */
    width: 100%;
    padding: 0px;
  }

  .contactSub_cont2 {
    border-radius: 10px;
    height: auto;
    padding: 10px;
  }

  .welcomeFormLabel {
    font-size: 14px;
  }

  .welcomeFormInput {
    width: 100%;
    font-size: 14px;
  }


  /* FOOTER */
  .welcomeFooterMain_cont {
    /* border: 1px solid purple; */
    display: flex;
    padding: 0px;
    justify-content: center;
    align-items: center;
  }

  .welcomeCopyright_cont {
    font-size: 14px;
    display: flex;
  }
}

/* ==================== N E W   H O M E P A G E ======================= */


.welcomeMainContentMain_cont {
  background-color: #fff;
  /* background-color: var(--background-color); */
}

/* S E C T I O N  1 */

.welcomeSection1 {
  /* border: 1px solid red; */
  width: 100%;
  height: 88vh;
  padding: 20px 30px;
  margin-top: 12vh;
}

.welcomeSection1Sub{
  /* border: 1px solid blue; */
  border-radius: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(../../../assets/Landing-Page1.jpeg);
  /* background-image: url(../../../assets/LandingPage1.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.welSecSubCont{
  /* border: 1px solid blue; */
  width: 100%;
  height: auto;
  padding: 20px 0px;
  text-align: center;
}

.welSecSubCont h1{
  width: 60%;
  margin: 0px auto;
  color: #fff;
  font-size: 38px;
}
.welSecSubCont p{
  width: 75%;
  margin: 0px auto;
  font-size: 17px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 20px;
}

.welcomeButton{
  border: none;
  border-radius: 10px;
  background-color: #ffd133;
  padding: 10px 20px;
  font-size: 17px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

/* S E C T I O N   2  */

.welcomeSection2 {
  /* border: 1px solid red; */
  width: 100%;
  height: 60vh;
  padding: 30px 100px;
}

.enquireFormMain_cont{
  /* border: 1px solid blue; */
  border-radius: 10px;
  background-color: #2b2b2b;
  height: 100%;
  width: 100%;
  padding: 20px 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 4px 15px rgba(0, 0, 0, 0.1);
}

.enquireFormMain_cont h3{
  text-align: center;
  margin-bottom: 10px;
  color: #fff;
}

.enquireFormRow{
  /* border: 1px solid red; */
  display: flex;
}

.enquireFormInput_cont{
  /* border: 1px solid blue; */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.enquireFormLabel{
  color: #fff;
  margin: 5px 0px;
}

.enquireFormInput{
  height: 42px;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  border: none;
}

.enquireFormTextarea{
  /* height: 42px; */
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  border: none;
  overflow: hidden;
  resize: none;
}

.welEnquireBtn_cont{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.welcomeEnquireButton{
  border: none;
  border-radius: 5px;
  background-color: #ffd133;
  padding: 10px 16px;
  font-size: 16px;
  width: 150px;
  cursor: pointer;
}

/* S E C T I O N   3  */

.welcomeSection3 {
  /* border: 1px solid red; */
  width: 100%;
  height: 88vh;
  padding: 20px 30px;
}

.welcomeSection3Sub {
  /* border: 1px solid blue; */
  border-radius: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(../../../assets/Landing-Page3.jpeg);
  /* background-image: url(../../../assets/LandingPage3.png); */
  background-size: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

/* S E C T I O N   4  */

.welcomeSection4 {
  /* border: 1px solid red; */
  width: 100%;
  height: 60vh;
  height: auto;
  padding: 20px 0px;
}

.welSecContentRow {
  /* border: 1px solid green; */
  height: auto;
  width: 70%;
  margin: 0px auto;
  display: flex;
}

.contentRow_cont1 {
  /* border: 1px solid black; */
  width: 30%;
  height: 100%;
  padding: 10px;
  font-weight: 700;

}

.contentRow_cont2 {
  /* border: 1px solid black; */
  width: 70%;
  height: 100%;
  padding: 10px;
}

/* S E C T I O N   5  */

.welcomeSection5 {
  /* border: 1px solid red; */
  width: 100%;
  height: 88vh;
  padding: 20px 30px;
}

.welcomeSection5Sub {
  /* border: 1px solid blue; */
  border-radius: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(../../../assets/Landing-Page5.jpeg);
  /* background-image: url(../../../assets/LandingPage5.jpg); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

/* S E C T I O N   6  */

.welcomeSection6 {
  /* border: 1px solid red; */
  width: 100%;
  height: 50vh;
  height: auto;
  padding: 20px 0px;
}

/* S E C T I O N   7  */

.welcomeSection7 {
  /* border: 1px solid red; */
  width: 100%;
  height: 88vh;
  padding: 20px 30px;
}

.welcomeSection7Sub {
  /* border: 1px solid blue; */
  border-radius: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(../../../assets/Landing-Page7.jpeg);
  /* background-image: url(../../../assets/LandingPage7.jpg); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

/* S E C T I O N   8  */

.welcomeSection8 {
  /* border: 1px solid red; */
  width: 100%;
  height: 50vh;
  height: auto;
  padding: 20px 0px;
  padding-bottom: 70px;
  display: flex;
  justify-content: space-evenly;
}
.contentCardCont{
  border: 1px solid lightgrey;
  border-radius: 10px;
  height: auto;
  width: 400px;
  padding: 15px;
}

.contentCardHead{
  font-weight: 700;
  margin-bottom: 8px;
}

.contentCardPara{
  font-size: 14px;
}

/* S E C T I O N   9  */

.welcomeSection9 {
  /* border: 1px solid red; */
  width: 100%;
  height: auto;
  padding: 0px 30px;

}

.whyZumiMain_cont{
  /* border: 1px solid blue; */
  height: auto;
  width: 100%;
  position: absolute;
  top: -9%;
}

.whyZumiCont{
  width: 300px;
  background-color: #ffd133;
  border-radius: 10px;
  margin: 0px auto;
  padding: 15px 20px;
  box-sizing: border-box;
  text-align: center;
  color: black;
}

.welSec9SubCont1{
  /* border: 1px solid red; */
  border-radius: 10px;
  width: 100%;
  height: auto;
  padding-bottom: 50px;
  background-image: url(../../../assets/welcomeSection9.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  position: relative;
  padding-top: 50px;
}

.welSec9SubCont2{
  /* border: 1px solid blue; */
}

.welSec9SubCont2 p{
  font-size: 20px;
  font-weight: 700;
  width: 60%;
  margin: 40px auto;
  text-align: center;
}

/* S E C T I O N   10  */
.welcomeSection10{
  /* border: 1px solid red; */
  width: 100%;
  height: 50vh;
  height: auto;
  padding: 20px 30px;
}

.whyWhatsappMain_cont{
  /* border: 1px solid green; */
}

.whyWhatsappCont{
  width: 500px;
  background-color: #ffd133;
  border-radius: 10px;
  margin: 0px auto;
  padding: 15px 20px;
  /* box-sizing: border-box; */
  text-align: center;
  color: black;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.welSec10SubCont{
  border-radius: 10px;
  height: auto;
  background-color: black;
  padding-bottom: 30px;
}

.welSec10WhatsappRow1{
  /* border: 1px solid white; */
  height: 300px;
  width: 100%;
  display: flex;
  color: #fff;
}

.whatsappRow1Cont1{
  /* border: 1px solid green; */
  height: 100%;
  width: 55%;
  padding: 0px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.whatsappRow1Cont1 h2{
  margin-bottom: 10px;
}

.whatsappRow1Cont2{
  /* border: 1px solid green; */
  height: 100%;
  width: 45%;
  text-align: center;
}

.welSec10WhatsappRow2{
  /* border: 1px solid white; */
  height: 300px;
  width: 100%;
  display: flex;
  color: #fff;
}

.whatsappRow2Cont1{
  /* border: 1px solid green; */
  height: 100%;
  width: 45%;
  text-align: center;
}

.whatsappRow2Cont2{
  /* border: 1px solid green; */
  height: 100%;
  width: 55%;
  text-align: right;
  padding: 0px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.whatsappRow2Cont2 h2{
  margin-bottom: 10px;
}
.whatsappRow2Cont2 p{
}

/* S E C T I O N   11  */
.welcomeSection11 {
  /* border: 1px solid red; */
  width: 100%;
  height: 50vh;
  height: auto;
}
.welSec11SubCont1{
  /* border: 1px solid green; */
  text-align: center;
}
.welSec11SubCont1 h1{
  font-size: 40px;
}

.welSec11SubCont1 p{
  margin: 20px auto;
  width: 50%;
  text-align: center;
}

.welSec11SubCont2{
  /* border: 1px solid blue; */
  height: 550px;
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
}

.welPriceCardCont{
  width: 27%;
  height: 100%;
  border-radius: 10px;
  background-color: black;
  padding: 0px 20px;
  color: #fff;
}

.welPlanNameCont{
  width: 280px;
  background-color: #ffd133;
  border-radius: 10px;
  margin: 0px auto;
  padding: 15px 20px;
  text-align: center;
  color: black;
  position: relative;
  bottom: 6%;
}

.welPlanPriceCont{
  /* border: 1px solid blue; */
  text-align: center;
}

.welPlanPriceCont h1{
font-size: 40px;
}

.pricingFeatureList{
  /* border: 1px solid red; */
  margin: 20px auto;
  padding-left: 20px;
}

.welSelectPlanBtn{
  text-align: center;
}

/* S E C T I O N   12  */

.welcomeSection12{
  /* border: 1px solid red; */
  width: 100%;
  height: 50vh;
  height: auto;
  padding: 50px;
}

.welContactCont {
  /* border: 1px solid blue; */
  border-radius: 10px;
  height: 120px;
  width: 100%;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
}

.welContactLabel {
  background-color: #ffd133;
  color: black;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  transform: rotate(-90deg);
  position: relative;
  /* right: 3.5%; */
  left: -40px;
}

.welContactFooter{
  /* border: 1px solid green; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-right: 10%;
}

.welContactContent {
  /* border: 1px solid blue; */
  width: 30%;
  display: flex;
  justify-content: space-between;
}

.welContactItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.termsContFooter{
  /* border: 1px solid red; */
  width: 30%;
  display: flex;
}

.termsLink{
  text-decoration: none;
  color: black;
  margin-right: 8px;
  margin-left: 8px;
}