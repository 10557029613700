.ordersChartHeadMain_cont{
  /* border: 1px solid blue; */
  display: flex;
}


.ordersChartMainHead_cont{
  /* border: 1px solid red; */
  width: auto;
  width: 50%;
}

.ordersChartTotalMain_cont{
  /* border: 1px solid red; */
  width: 100%;

}

.OrderTotalMain1{
  display: flex;
}

.orderTotalCont1{
  /* border: 1px solid green; */
  width: 50%;
}

.orderTotalCont2{
  /* border: 1px solid green; */
  width: 50%;
}