.connectMain_cont{
  /* border: 2px solid blue; */
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  overflow-y: scroll;
  padding: 20px;
  padding-bottom: 30px;
  scrollbar-width: none;
}

.connectMain_cont::-webkit-scrollbar{
  display: none;
}

.connect_cont{
  width: 100%;
  height: 82%;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.smallPara{
  font-size: 14px;
  margin-bottom: 10px;
}
.connectSub1_cont{
  margin-top: 40px;
}
.connectSub2_cont{
  margin-top: 40px;
}
.connectBtn_cont{
  /* border: 1px solid blue; */
  margin-top: 40px;
}