.scheduleTaskMain{
  height: 470px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 0px;
    background-color: #f8f9fa;
    overflow: auto;
    overflow-y: scroll;
    scrollbar-width: none;
}

.shortenUrl_cont{
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.scheduleTaskBtn_cont{
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}