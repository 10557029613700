@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
@import '../../../App.css';

.newMarketMain_cont{
  /* border: 1px solid blue; */
  height: 100%;
  width: 100%;
  padding: 15px;
}


.newMarketSub_cont{
  background-color: #c7cdff;
  border-radius: 10px;
  padding: 15px;
  height: 100%;
  width: 100%;
  display: flex;
}


.newMarketCont1{
  /* border: 1px solid #fff; */
  background-color: #fff;
  border-radius: 10px;
  height: 100%;
  width: 50%;
  margin-right: 15px;
  padding: 20px;
  padding-top: 10px;
  color: rgb(23, 193, 232);
}

.newMarketHead_cont{
  /* border: 1px solid blue; */
  height: 10%;
  width: 100%;
  text-align: center;
}

.newMarketCarousel_cont{
  /* border: 1px solid blue; */
  height: 85%;
  width: 100%;
  padding: 0px;
}

.carouselImg{
  height: 100%;
  width: 100%;
}

.marketAmazonImg_cont{
  /* border: 1px solid blue; */
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marketAmazonImg_cont img{
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.marketShopifyImg_cont{
  /* border: 1px solid blue; */
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marketShopifyImg_cont img{
height: 100%;
width: 100%;
border-radius: 10px;
}

.marketEbayImg_cont{
  /* border: 1px solid blue; */
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marketEbayImg_cont img{
  height: 100%;
  width: 100%;
  border-radius: 10px;
}


.newMarketCont2{
  /* border: 1px solid #fff; */
  background-color: #fff;
  border-radius: 10px;
  height: 100%;
  width: 50%;
  padding: 20px;
  padding-top: 10px;
}

.stepper_cont{
  /* border: 1px solid blue; */
}

.marketStepper{
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
}


/* -------------------------------------- */

.newMarketForm_cont1{
  /* border: 1px solid rgb(14, 113, 195); */
  border-radius: 10px;
  padding: 20px;
  padding-top: 0px;
}

.newMarketInput_cont{
  /* border: 1px solid red; */
  width: 100%;
  margin-bottom: 15px;
}

.regionSelect{
  width: 100%;
}

.newMarketplaceBtn_cont{
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

.newMarketError_cont{
  /* border: 1px solid blue; */
  margin-top: 10px;
}

.selectedMarketplaceName_cont{
  /* border: 1px solid blue; */
  height: 40px;
  text-align: center;
  color: var(--secondary-color);

}

/* ------------------------------------- */


.amazonMarketForm_cont{
  /* border: 1px solid rgb(14, 113, 195); */
  border-radius: 10px;
  padding: 20px;
  padding-top: 0px;
}

.marketBackBtn{
  text-decoration: none;
  display: flex;
  align-items: center;
}