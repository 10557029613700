.revenue_main {
  /* border: 2px solid green; */
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-y: scroll;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
  scrollbar-width: none;
}

.revenue_main::-webkit-scrollbar{
  display: none;
}

.revenueBtn_cont {
  /* border: 1px solid blueviolet; */
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 8vh;
}

.revenueTableMain_cont {
  /* border: 1px solid red; */
  height: auto;
  padding-top: 30px;
  display: flex;
  justify-content: center;
}

.revenueTable_cont {
  /* border: 2px solid blue; */
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.revenueMainChartMain_cont {
  /* border: 1px solid red; */
  width: 100%;
  margin-top: 20px;
  height: 70vh;
}

.revenueMainChart_cont {
  /* border: 2px solid green; */
  border-radius: 10px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.revenueMainChart {
  /* border: 2px solid red; */
  height: 70%;
}

.revenueLegendMain_cont {
  /* border: 1px solid violet; */
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.revenueLegendSub_cont{
  /* border: 1px solid blue; */
  /* width: 30%; */
  height: 100%;
  display: flex;
}

.legend1_cont{
  width: auto;
  height: 100%;
  /* background-color: blue; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 0px 10px;
}
.legend1{
  background-color: #D1E9F6;
  height: 40%;
  width: 30px;
}

.legendName{
  width: auto;
  margin-left: 5px;
  font-size: 13px;
}

.legend2_cont{
  width: auto;
  height: 100%;
  /* background-color: red; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 0px 10px;
}

.legend2 {
  /* background-color: #D1E9F6; */
  height: 40%;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.legend3_cont{

}

.legend3{
  background-color: #E8C5E5;
  height: 40%;
  width: 30px;
}

.legend4_cont{
}

.legend4{
  height: 40%;
    width: 30px;
    display: flex;
    justify-content: center;
  align-items: center;

}
