.publicDealsMain{
  /* height: 100%;
    width: 100%; */
    box-sizing: border-box;
    overflow: auto;
    overflow-y: scroll;
    scrollbar-width: none;
    /* border: 1px solid blue; */
}

.publicDealsSubCont{
/* border: 2px solid red; */
padding: 20px;
  
}

.publicDealsMain::-webkit-scrollbar {
  display: none;
}