.editUserSub_cont{
  /* border: 1px solid blue; */
    height: auto;
    padding: 10px 20px;
    padding-bottom: 20px;
    /* background-color: #f8f9fa; */
}

.editUserDetails_cont{
  border: 1px solid lightgrey;
  border-radius: 10px;
  width: 100%;
  height: auto;
  padding: 20px;
  display: flex;
}


.editUserHeadCont{
  /* border: 1px solid blue; */
  width: auto;
  height: 100%;
  font-size: 14px;
  font-weight: 700;
  margin-right: 15px;
}

.editUserdetail_cont{
  /* border: 1px solid green; */
  width: 50%;
  height: 100%;
  font-size: 14px;
}


.editUserFormCont{
  /* border: 1px solid blue; */
  border-radius: 10px;
  height: auto;
  padding: 10px 20px;
  padding-bottom: 20px;
  /* background-color: #f8f9fa; */
}

.userStatusToggle{
  border: 1px solid lightgrey;
    border-radius: 10px;
    height: 56px;
    width: 160px;
    padding: 6px;
    display: flex;
    margin-bottom: 10px;
}

.inactiveTab {
  /* border: 1px solid green; */
  border-radius: 10px;
  color: black;
  font-size: 14px;
  width: 50%;
  height: 100%;
  margin-right: 10px;
  text-decoration: none;
  cursor: pointer;
  transition: 0.4s;
}

.activeTab {
  /* border: 1px solid green; */
  border-radius: 10px;
  color: black;
  font-size: 14px;
  width: 50%;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
  transition: 0.4s;
}


.inactiveUserTabActive {
  background-color: var(--primary-color);
  background-color: rgb(255, 231, 231);
  color: #fff;
  color: rgb(255, 14, 14);
}
.activeUserTabActive {
  background-color: var(--primary-color);
  background-color: rgb(233, 246, 238);
  background-color: #BFF6C3;
  color: #fff;
  color: rgb(35, 164, 85);
}

.updateUserBtn_cont{
  display: flex;
  /* justify-content: flex-end; */
  margin-top: 10px;
}
