.buyCreditsMain_cont{

}

.buyCreditsHeader{
  /* border: 1px solid blue; */
  height: 150px;
  width: 100%;
}

.buyCreditsHeaderSub{
  /* border: 1px solid red; */
  height: 100%;
  width: 200px;
}

.buyCreditsHeaderSvg{
  /* border: 1px solid green; */
  border-radius: 50%;
  height: 80px;
  width: 80px;
  margin: 0px auto;
  margin-top: 20px;
  background-color: #B6FFA1;
  background-color: #d4f4e6;
}

.buyCreditsHeaderSvg img{
  height: 60%;
  width: 60%;
}

.buyCreditsHead{
  /* border: 1px solid blue; */
  width: 100%;
  text-align: center;
  margin-top: 15px;
  font-weight: 700;
  font-size: 18px;
}



.buyCreditsSub_cont{
  /* border: 1px solid blue; */
  height: auto;
  width: 100%;
  height: 300px;
  padding: 20px;
  padding-top: 10px;

}

.creditSelectRow{
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.priceCredit{
  /* border: 1px solid grey; */
  padding: 12px 10px;
  height: 56px;
  min-width: 120px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  background-color: #e3efff;
  color: #74b3f5;
}


.enterPrice_cont{
  /* border: 1px solid purple; */
  width: 80px;
}

.buyCreditInput{
  width: 120px;
}

.m-b-10{
  margin-bottom: 10px;
}
.m-b-20{
  margin-bottom: 20px;
}
.buyCreditsSub_cont span{
  color: grey;
}

.buyCreditBtn_cont{
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}