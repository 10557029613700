.newUserForm_cont{
  /* border: 1px solid blue; */
  height: auto;
  padding: 10px 20px;
  padding-bottom: 20px;
  /* background-color: #f8f9fa; */
}

.newUserRow {
  /* border: 1px solid red; */
  display: flex;
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.newUserFormInput_cont {
  /* border: 1px solid blue; */
  width: 100%;
  height: 100%;
}

.addUserBtn_cont{
  /* border: 1px solid blue; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.newUserAlert_cont{
  /* border: 1px solid blue; */
  padding: 5px 20px;
}

.newUserContact_cont{
  display: flex;
}