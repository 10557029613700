@import '../../../App.css';

.downloadCatalogs_cont{
  /* border: 1px solid purple; */
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  
}
.downloadCatalogsBtn{
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  background-color: var(--primary-color);
  padding: 10px;
  margin-right: 20px;
  color: #fff;

}

.downloadCatalogsBtn:hover{
  background-color: #fff;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  transition: 0.3s;
}

.catBtnsCont{
  /* border: 1px solid green; */
}

.uploadCatalogsMain{
  /* border: 1px solid blue; */
  height: 100%;
  width: 100%;
  
}

.uploadCatalogsSub{
  padding: 0px 20px;
  
}

.uploadCatalogsSub p{
  font-size: 15px;
  color: gray;
}

.dragDropCatalogs_cont {
  border: 1px dashed blue;
  border-radius: 10px;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: auto;
}

.uploadCatalogsBtn_cont{
  /* border: 1px solid blue; */
  display: flex;  
  justify-content: flex-end;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.catalogNavBarMain{
  /* border: 1px solid blue; */
  height: 38px;
  width: 50%;
  display: flex;

}

.catItem{
  width: 50%;
  height: 100%;
  background-color: #E8F9FF;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.catItem::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: rgb(64, 178, 224);
  transition: width 0.3s ease-in;
}

.catalogsActiveTab::before {
  width: 100%;
}


.catalogsActiveTab {
  border-bottom: 2px solid rgb(64, 178, 224);
  transition: border-bottom 0.3s ease-in;
}


