@import '../../App.css';

.atmnMain_cont{
  /* border: 1px solid blue; */
  height: 100%;
  width: 100%;
  overflow: auto;
    overflow-y: scroll;
    padding: 20px;
    scrollbar-width: none;
}

.replyMatMain_cont{
  /* border: 1px solid blue; */
  height: auto;
  width: 100%;
}

.noReplyMaterialCont{
  /* border: 1px solid red; */
  height: 300px;
  width: 500px;
  margin: 20px auto;
}

.noReplyMaterialCont img{
  height: 100%;
  width: 100%;
}

.replyMat_cont{
  /* border: 1px solid green; */
  height: auto;
  width: 100%;
  margin: 20px 0px;

  display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Create 3 equal columns */
    gap: 30px;
}

.replyMatCardMain_cont{
  /* border: 1px solid purple; */
  border: 1px solid #e2eefe;
  border-radius: 10px;
  height: 200px;
  /* width: 30%; */
  box-shadow: 4px 4px 6px #87b8ea;
}

.replyMatTitle_cont{
  /* border: 1px solid red; */
  background-color: #e2eefe;
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  font-size: 15px;
  font-weight: 700;
  color: var(--secondary-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
 
}


.replyMatDesc_cont{
  /* border: 1px solid green; */
  height: 80%;
  padding: 0px 20px;
  padding-top: 20px;
  font-size: 14px;
  color: #3C3D37;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;
}

.replyMatBtns_cont{
  display: flex;
}

.replyMatBtn_cont{
  /* border: 1px solid blue; */
  height: 28px;
  width: 28px;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  background-color: skyblue;
}

.replyMatBtn_cont:hover{
  color: #fff;
}

.automationtabActive{
  color: var(--primary-color);
  cursor: pointer;
}

.keyAct_cont{
  height: auto;
  width: 100%;
  margin: 20px 0px;

}

.keyActCardMain_cont{
  /* border: 1px solid blue; */
  border: 1px solid #e2eefe;
  border-radius: 10px;
  height: 80px;
  width: 100%;
  margin-bottom: 5px;
  /* box-shadow: 4px 4px 6px #87b8ea; */
  display: flex;
}

.whatToolsLink{
  cursor: pointer;
}

.keyActHeadCard{
  border: 1px solid var(--primary-color);
}

.keywordName_cont{
/* border: 1px solid blue; */
width: 20%;
}

.keyTriggered_cont{
/* border: 1px solid blue; */
width: 10%;
}

.matchingMethod_cont{
/* border: 1px solid blue; */
width: 20%;
}

.replyMaterial_cont{
/* border: 1px solid blue; */
width: 30%;
}

.keyActions_cont{
  /* border: 1px solid blue; */
  width: 20%;
}