@import '../../App.css';

.smsMain_cont{
  /* border: 1px solid blue; */
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  overflow-y: scroll;
  padding: 20px;
  padding-bottom: 30px;
  scrollbar-width: none;
}


.scheduleTaskBtn{
  background-color: rgb(233, 246, 238);
  color: rgb(35, 164, 85);
  border: 1px solid rgb(233, 246, 238);
  height: 40px;
  text-decoration: none;
  padding: 1px 10px;
  letter-spacing: 0.3px;
}

.taskDetailsView{
  background-color: rgb(233, 246, 238);
  border: 1px solid rgb(233, 246, 238);
  color: rgb(35, 164, 85);
  height: 40px;
  text-decoration: none;
  padding: 1px 10px;
  letter-spacing: 0.3px;
  min-width: 70px;
}

.scheduleTaskBtn:hover{
  border: 1px solid rgb(35, 164, 85);
  background-color: rgb(35, 164, 85);
  color: rgb(233, 246, 238);
}
.taskDetailsView:hover{
  border: 1px solid rgb(35, 164, 85);
  background-color: rgb(35, 164, 85);
  color: rgb(233, 246, 238);
}


/* Define the animation for sliding in and out */
@keyframes slideIn {
  0% {
    transform: translateX(100%);
    /* Start from the right */
  }

  100% {
    transform: translateX(0);
    /* End at the normal position */
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
    /* Start at the normal position */
  }

  100% {
    transform: translateX(100%);
    /* End at the right side */
  }
}

/* Alert container that slides in and out */
.alert-container {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 8px 15px;
  color: black;
  border-radius: 10px;
  z-index: 9999;
  animation: slideIn 0.5s ease-out forwards;
}

.alert-container.slide-out {
  animation: slideOut 0.5s ease-out forwards;
}

.alert-message {
  font-size: 14px;
}