.newRoleHeader {
  /* border: 1px solid green; */
  margin-bottom: 0px;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
}

.newRoleForm_cont {
  /* border: 1px solid blue; */
  /* height: 200px; */
  padding: 10px 20px;
  padding-bottom: 20px;
  /* background-color: #f8f9fa; */
}

.newRoleFormInput_cont{
  /* border: 1px solid blue; */
  margin-bottom: 10px;
}

.rolePrivilegeTable_cont{
  /* border: 1px solid green; */
  border: 1px solid lightgray;
  border-radius: 10px;
  height: 9em;
  width: 100%;
  width: 80%;
  margin: auto;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  position: relative;
}

.rolePrivilegeHeadRow{
  /* border: 1px solid green; */
  border-bottom: 1px solid lightgrey;
  width: 100%;
  display: flex;
  height: 30px;
  position: sticky;
  background-color: #fff;
  top: 0;
  z-index: 1;
}

.rolePrivilegeHeadCell{
  width: 50%;
  text-align: center;
}

.rolePrivilegeRow{
  /* border: 1px solid red; */
  display: flex;
  width: 100%;
  height: 30px;
}

.rolePrivilegeCell{
  /* border: 1px solid blue; */
  width: 50%;
  font-size: 15px;
}

.addRoleBtn_cont{
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}