.makeOfferMain {
  /* border: 1px solid blue; */
  width: 100%;
  height: 100%;
  padding: 20px;
}

.makeOfferSub_cont {
  border: 1px solid lightgray;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.makeOfferSub_cont2 {
  border: 1px solid lightgray;
  border-radius: 10px;
  width: 70%;
  height: 100%;
  padding: 20px;
  padding-top: 10px;
}

.offerProductName_cont{
  height: 80px;
}

.offerPrice{
  margin-top: 10px;
  font-size: 20px;
}

.offerFields_cont{
  /* border: 1px solid red; */
  display: flex;
}

.makeOfferBtn_cont{
  /* border: 1px solid blue; */
  margin-top: 10px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
}