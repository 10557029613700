@import '../../../App.css';

.createSmsMain{
  /* border: 2px solid green; */
  height: 470px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 0px;
  background-color: #f8f9fa;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;

}

.createSmsStepsCont{
  height: auto;
  width: 80%;
  margin: 0px auto;
}

.createSmsCont1{
  height: auto;
  width: 100%;
}

.createSmsRow {
  /* border: 1px solid red; */
  display: flex;
  width: 100%;
  height: auto;
  margin-bottom: 5px;
}

.createSmsInput_cont{
  width: 100%;
  /* border: 1px solid blue; */
}

.smsInputField{
  width: 100%;
  margin-top: 5px;
}

.createSmsNextCont{
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.createSmsCont2{
  height: 100%;
  width: 100%;
  overflow: auto;
    overflow-y: scroll;
    scrollbar-width: none;
}

.dateRangePickerMain_cont{
  /* border: 1px solid red; */
}

.demoInputMUI{
  border: 1px solid #afb3b7;
  border-radius: 5px;
  height: 56px;
  display: flex;
  align-items: center;
}

.uploadSmsFileLabel {
  background: none;
  padding: 1px 8px;
  border-radius: 10px;
  cursor: pointer;
  border-width: 1px;
  border: 1px solid rgb(14, 113, 195);
  color: rgb(14, 113, 195);
  font-size: 13px;
  transition: box-shadow 0.3s ease 0s;
  min-width: 100px;
  width: auto;
  height: 2.2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  transition: 0.3s;
}

.uploadSmsFileLabel:hover{
  border: 1px solid var(--primary-color);
  color: #fff;
  background-color: var(--primary-color);
}

.selectSegmentSms{
  width: 80%;
  margin-left: 10px;
  margin-right: 10px;
}

.orContainer{
  padding: 2px 5px;
  background-color: lightgray;
  border-radius: 50%;

}

.createSmsSaveCont{
  display: flex;
  justify-content: space-between;
}

.info-container {
  position: relative;
  display: inline-block;
}

.info-button {
  /* background-color: lightgray; */
  color: white;
  border: none;
  /* padding: 1px; */
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: inline-block; */
  color: gray;
  border-radius: 50%;
}


.tooltip {
  position: absolute;
  bottom: 100%;
  left: 550%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: normal;
  /* Change this from nowrap to normal */
  z-index: 10;
  opacity: 0.8;
  font-size: 10px;
  width: 250px;
  /* This sets the max width for the tooltip */
}